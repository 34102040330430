<template>
	<CModal
		:show.sync="popupModalProp.isShow"
		:closeOnBackdrop="false"
		class="modal-fullscreen">
		<template #header>
			<h5 class="modal-title">Susun Surat Masuk</h5>
			<CButtonClose @click="closePopupModal" class="text-gray"/>
		</template>
		<!-- Body -->
		<v-stepper v-model="e1">
			<!-- Stepper Header -->
			<v-stepper-header>
				<v-stepper-step :complete="e1 > 1" :editable="e1 > 1" step="1">Surat</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="e1 > 2" :editable="e1 > 2" step="2">Informasi Umum</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step step="3">Pratinjau</v-stepper-step>
			</v-stepper-header>

			<!-- Stepper Content -->
			<v-stepper-items>
				<v-overlay
					:absolute="true"
					:value="overlayState">
					<v-progress-circular
						indeterminate
						color="red">
					</v-progress-circular>
					{{ overlayText }}
				</v-overlay>
				<!-- Attachment -->
				<v-stepper-content step="1">
					<div style="min-height:60vh">
						<div class="mb-20">
							<label class="typo__label">Sumber Surat <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
							<multiselect
								v-model="attachmentForm.input.source"
								:allow-empty="false"
								:close-on-select="true"
								:disabled="attachmentForm.sourceOptionsDisabled"
								:maxHeight="300"
								:multiple="false"
								:options="attachmentForm.sourceOptions"
								deselectLabel="Tekan 'enter' untuk menghapus"
								openDirection="bottom"
								selectLabel="Tekan 'enter' untuk memilih"
								selectedLabel="Terpilih"
                                @input="selectSource">
							</multiselect>
						</div>
						<!-- Upload Document -->
						<div v-if="attachmentForm.input.source == 'Lampiran Surel'" class="mb-20">
							<CInput
								v-model="attachmentForm.input.selectedEmailDoc.subject"
								label="Subjek Surel"
								disabled />
							<CInput
								v-model="attachmentForm.input.filename"
								label="Nama Surat"
								disabled />
							<CInput
								v-model="attachmentForm.input.selectedEmailDoc.source"
								label="Sumber"
								disabled />
							<CInput
								v-model="attachmentForm.input.selectedEmailDoc.sentAt"
								label="Tanggal Email"
								disabled />
						</div>
						<div v-else-if="attachmentForm.input.source == 'Unggah Surat'">
							<div role="group" class="form-group">
								<label class="typo__label">Pilih Surat <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
								<CInputFile
									ref="file"
									accept=".doc,.docx,.pdf"
									:placeholder="attachmentForm.placeholder"
									custom
									@change="validateAttachmentForm"/>
								<CInput
									v-model="attachmentForm.input.filename"
									type="hidden" />
								<!--<small class="d-inline-block form-text text-danger">(*) Berkas di bawah 5MB dan/atau di bawah 20 halaman</small> -->
							</div>
						</div>
						<div v-else-if="attachmentForm.input.source == 'Pindai Surat'">
							<!-- <iframe src="http://localhost:3000/scan.html" id="scanner-iframe" frameborder="0" scrolling="no" width="100%" height="650px"></iframe> -->
							<!-- <iframe :src="baseURL + '/scan.html'" id="scanner-iframe" frameborder="0" scrolling="no" width="100%" height="600px"></iframe> -->
							<Twain
								v-bind:pdf-data="pdfData"
								v-bind:container-id="`dwtControlContainerIncoming`"
							/>
						</div>
					</div>
				</v-stepper-content>
				<!-- General Information -->
				<v-stepper-content step="2">
					<div class="mb-20">
						<label class="typo__label">Nomor Agenda <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
						<CInput
							class="mb-0"
							v-model="documentForm.input.agendaNumber"
							placeholder="Nomor Agenda"
							:is-valid="documentForm.validate.agendaNumber"
							@change="validateDocumentForm('agendaNumber')"/>
						<label class="pl-1"><small class="d-inline-block form-text ">Nomor Agenda Terakhir : {{lastNoAgenda.join(", ")}}</small></label>
						<!-- Takah -->
					<div class="mb-20">
						<div class="mb-20">
							<label class="typo__label">Takah</label>
							<multiselect
								v-model="documentForm.input.takah"
								:allow-empty="true"
								:close-on-select="true"
								:maxHeight="300"
								:multiple="false"
								:options="documentForm.takahOptions"
								deselectLabel="Tekan 'enter' untuk menghapus"
								label="label"
								openDirection="top"
								placeholder="Pilih takah"
								selectLabel="Tekan 'enter' untuk memilih"
								selectedLabel="Terpilih"
								track-by="id">
							</multiselect>
						</div>
					</div>
						<label class="typo__label">Dari <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
						<CInput
						v-model="documentForm.input.sender"
						placeholder="Dari"
                        :is-valid="documentForm.validate.sender"
                        @change="validateDocumentForm('sender')"/>
						<label class="typo__label">Nomor Surat <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
						<CInput
							v-model="documentForm.input.docNumber"
							placeholder="Nomor Surat"
							:is-valid="documentForm.validate.docNumber"
							@change="validateDocumentForm('docNumber')"/>
						<small v-if="documentForm.validate.duplicateNumber === true" class="d-inline-block form-text text-danger" style="margin-top:-10px">Duplikat nomor surat!</small>
						<!-- Document Date -->
						<div class="form-input">
							<label class="label">Tanggal Surat</label>
							<date-picker
								v-model="documentForm.input.createdAt"
								placeholder="YYYY-MM-DD"
								value-type="format"
								type="date"
								class="w-100">
							</date-picker>
						</div>
						<label class="typo__label">Perihal <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
						<CInput
							v-model="documentForm.input.title"
							placeholder="Perihal"
							:is-valid="documentForm.validate.title"
							@change="validateDocumentForm('title')"/>
						<CInput
							v-model="documentForm.input.docFileId"
							type="hidden"/>
						<div class="mb-20">
							<label class="typo__label">Kategori <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
							<multiselect
								v-model="documentForm.input.categorySelected"
								:allow-empty="false"
								:close-on-select="true"
								:maxHeight="200"
								:multiple="false"
								:options="documentForm.categoryOptions"
								deselectLabel="Tekan 'enter' untuk menghapus"
								label="label"
								openDirection="bottom"
								placeholder="Pilih Kategori Surat"
								selectLabel="Tekan 'enter' untuk memilih"
								selectedLabel="Terpilih"
								track-by="id"
								@input="validateDocumentForm('categorySelected')">
								<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
							</multiselect>
						</div>
						<div>
							<label class="typo__label mb-0">Sifat Surat <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
							<CInputRadioGroup
								v-model="documentForm.input.trait"
								class="col-sm-9"
								:options="traitOptions"
								:inline="true"
								checked="Biasa"
								@update:checked="validateDocumentForm('trait', $event)" />
						</div>
						<div>
							<label class="typo__label mb-0">Derajat Surat <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
							<CInputRadioGroup
								v-model="documentForm.input.level"
								class="col-sm-9"
								:options="levelOptions"
								:inline="true"
								checked="Biasa"
								@update:checked="validateDocumentForm('level', $event)" />
						</div>
					</div>
					<!-- Workflow -->
					<div class="mb-20">
						<label class="typo__label">Gunakan Konfigurasi? <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
						<multiselect
							v-model="documentForm.input.selectedVerificationOption"
							:allow-empty="false"
							:close-on-select="true"
							:multiple="false"
							:options="workflowForm.isVerificationOptions"
							deselectLabel="Tekan 'enter' untuk menghapus"
							label="label"
							placeholder="Gunakan Konfigurasi?"
							selectLabel="Tekan 'enter' untuk memilih"
							selectedLabel="Terpilih"
							track-by="value"
							@input="selectWorkflowOption">
						</multiselect>
					</div>
					<div v-if="documentForm.input.isVerification == 'yes'" class="mb-20">
						<label class="typo__label">Alur Konfigurasi <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
						<multiselect
							v-model="documentForm.input.selectedVerification"
							:allow-empty="false"
							:close-on-select="true"
							:maxHeight="300"
							:multiple="false"
							:options="workflowForm.workflowOptions"
							deselectLabel="Tekan 'enter' untuk menghapus"
							label="workflow_config"
							openDirection="top"
							placeholder="Pilih Konfigurasi Alur Verifikasi"
							selectLabel="Tekan 'enter' untuk memilih"
							selectedLabel="Terpilih"
							track-by="workflow_id"
							@input="selectWorkflow">
						</multiselect>
					</div>
					<div v-else class="mb-20">
						<label class="typo__label">Penerima <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
						<multiselect 
							v-model="dispositionForm.input.selectedReceivers"
							:allow-empty="false"
							:close-on-select="true"
							:maxHeight="300"
							:multiple="false"
							:options="documentForm.receiverOptions"
							:is-valid="dispositionForm.validate.receivers"
							deselectLabel="Tekan 'enter' untuk menghapus"
							label="label"
							openDirection="top"
							placeholder="Pilih Penerima"
							selectLabel="Tekan 'enter' untuk memilih"
							selectedLabel="Terpilih"
							track-by="id"
							@input="selectReceiver">
						</multiselect>
					</div>
					<!-- Disposition -->
					<CInput
						v-model="documentForm.input.to"
						label="Tertuju Kepada"
						placeholder="Tertuju Kepada" />
					<!-- Tag -->
					<div class="mb-20">
						<div class="mb-20">
							<label class="typo__label">Tembusan</label>
							<multiselect
								v-model="documentForm.input.userTagged"
								:allow-empty="true"
								:close-on-select="false"
								:maxHeight="300"
								:multiple="true"
								:options="documentForm.tagOptions"
								deselectLabel="Tekan 'enter' untuk menghapus"
								label="label"
								openDirection="top"
								placeholder="Pilih beberapa pengguna untuk menandai"
								selectLabel="Tekan 'enter' untuk memilih"
								selectedLabel="Terpilih"
								track-by="id">
							</multiselect>
						</div>
					</div>
					<label class="">Informasi Tambahan</label>
					<div class="mb-20">
						<label class="">Ringkasan Surat</label>
						<CTextarea
							v-model="documentForm.input.description"
							placeholder="Tuliskan ringkasan surat"/>
					</div>
					<!-- Additional Attachment -->
					<label class="">Lampiran Tambahan</label>
					<div class="mb-20">
						<label class="">Pilih Dari Arsip Surat</label>
						<attachment-document
							v-model="documentForm.input.archiveDocAttachments"
						/>
					</div>
					<div role="group" class="form-group">
						<label class="">Unggah Dari Komputer</label>
						<CInputFile
							ref="additional_attachment"
							accept=".doc,.docx,.pdf"
							:placeholder="documentForm.placeholder"
							:multiple="true"
							custom
							@change="additionalAttachmentChange" />
					</div>
				</v-stepper-content>
				<!-- Preview -->
				<v-stepper-content step="3">
					<CRow>
						<CCol col="8">
							<document-preview v-bind:doc-file-id-prop="docPreviewFileId" />
						</CCol>
						<CCol col="4">
							<CCard class="p-0">
								<CCardBody>
									<table id="detail-info-table" class="table table-borderless">
										<tbody>
											<tr>
												<td width="100px">Dari</td>
												<td width="2px">:</td>
												<td>{{ documentForm.input.sender }}</td>
											</tr>
											<tr>
												<td width="90px">Kepada</td>
												<td width="2px">:</td>
												<td>{{ documentForm.input.to }}</td>
											</tr>
											<tr>
												<td width="90px">No. Surat</td>
												<td width="2px">:</td>
												<td>{{ documentForm.input.docNumber }}</td>
											</tr>
											<tr>
												<td width="90px">No. Agenda</td>
												<td width="2px">:</td>
												<td>{{ documentForm.input.agendaNumber }}</td>
											</tr>
											<tr>
												<td width="90px">Perihal</td>
												<td width="2px">:</td>
												<td>{{ documentForm.input.title }}</td>
											</tr>
											<tr>
												<td width="90px">Tipe Surat</td>
												<td width="2px">:</td>
												<td>{{ documentForm.input.type }}</td>
											</tr>
											<tr>
												<td width="90px">Sifat Surat</td>
												<td width="2px">:</td>
												<td>{{ documentForm.input.trait }}</td>
											</tr>
											<tr>
												<td width="90px">Level Surat</td>
												<td width="2px">:</td>
												<td>{{ documentForm.input.level }}</td>
											</tr>
											<tr>
												<td width="90px">Dibuat Pada</td>
												<td width="2px">:</td>
												<td>{{ this.$moment(documentForm.input.createdAt).format("Y-MM-DD, HH:mm") }}</td>
											</tr>
											<tr>
												<td colspan="3">
													<CButton 
														size="sm"
														color="secondary"
														@click="collapse.show = !collapse.show">{{ collapse.text }}
													</CButton>
												</td>
											</tr>
											<tr>
												<td colspan="3">
													<CCollapse :show="collapse.show">
														{{ documentForm.input.description }}
													</CCollapse>
												</td>
											</tr>
										</tbody>
									</table>

								</CCardBody>
							</CCard>
						</CCol>
					</CRow>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
		<template #footer>
			<CButton v-if="e1 == 1" @click="closePopupModal" class="btn-md">Batal</CButton>
			<CButton v-else class="btn-md" @click="goBack">Sebelumnya</CButton>

			<CButton v-if="e1 == 1" color="success" class="btn-md" @click="upload()" :disabled="attachmentForm.uploadBtn.disabled">
				<font-awesome-icon v-if="attachmentForm.uploadBtn.loader" icon="spinner" spin/> {{ attachmentForm.uploadBtn.text }}
			</CButton>
			<CButton v-else-if="e1 == 2" color="success" class="btn-md" @click="loadPreview()" :disabled="documentForm.submitBtn.disabled">
				Lanjut
			</CButton>
			<CButton v-if="e1 == 3" color="success" class="btn-md" @click="saveItem()" :disabled="documentForm.submitBtn.disabled">
				<font-awesome-icon v-if="documentForm.submitBtn.loader" icon="spinner" spin/> {{ documentForm.submitBtn.text }}
			</CButton>
		</template>
	</CModal>
</template>

<script>
import {
    mapState
} from 'vuex'

import AttachmentDocument from '@/components/document/AttachmentDocument.vue'
import DocumentPreview from '@/components/document/DocumentPreview.vue'
import Twain from '@/components/document/Twain.vue'
export default {
    props: {
		popupModalProp: {
			type: Object,
			required: true
		},
        categoryOptionsProp: {
			type: Array,
			default: function() {
				return null
			}
		},
		tagOptionsProp: {
			type: Array,
			default: function() {
				return null
			}
		},
		emailDocProp: {
			type: Object,
			default: function() {
				return null
			}
		},
		duplicateDocProp: {
			type: Object,
			default: function() {
				return null
			},
			required: false
		}
	},
	components: {
		'attachment-document': AttachmentDocument,
		'document-preview': DocumentPreview,
		Twain
	},
    data () {
        return {
			e1: 1,
            baseURL: process.env.VUE_APP_SERVER,
			saveState: {
				attachment: false,
				document: false,
				disposition: false
			},
            attachmentForm: {
				sourceOptions: ['Unggah Surat', 'Pindai Surat'],
				sourceOptionsDisabled: false,
                input: {
					source: 'Unggah Surat',
					selectedEmailDoc: null,
                    file: null,
					filename: null,
					fileBase64: [],
					scannedFileCount: 0
                },
                default: {
					source: 'Unggah Surat',
					selectedEmailDoc: null,
                    file: null,
					filename: null,
					fileBase64: [],
					scannedFileCount: 0
                },
                validate: {
                    file: null,
					filename: null
                },
                placeholder: 'Pilih Surat (DOC,DOCX,PDF)',
                uploadBtn: {
                    loader: false,
                    text: 'Lanjut',
                    disabled: true
                }
			},
            documentForm: {
				categoryOptions: [],
				receiverOptions: [],
				tagOptions: [],
				takahOptions: [],
                input: {
					title: null,
					docNumber: null,
					categoryId: null,
					categorySelected: null,
					docFileId: null,
					// Workflow
					isWorkflow: 'no',
					selectedVerificationOption: { label: 'Ya', value: 'yes' },
					isVerification: 'yes',
					selectedVerification: null,
					verification: null,
					// User Tagged
					tags: [],
					userTagged: [],
					// Additional Attachment
					archiveDocAttachments: [],
					attachmentsEDoc: [],
					additionalAttachments: [],
					attachments: [],
					// Marks
					type: 'Masuk',
					from: 'Eksternal',
					trait: 'Biasa',
					createdAt: null,
					sender: null,
					to: null,
					agendaNumber: null,
					level: 'Biasa',
					takah: null,
					description: null,
				},
				defaultValue: {
					docId: null,
					title: null,
					docNumber: null,
					categoryId: null,
					categorySelected: null,
					docFileId: '',
					// Workflow
					isWorkflow: 'no',
					selectedVerificationOption: { label: 'Ya', value: 'yes' },
					isVerification: 'yes',
					selectedVerification: null,
					verification: null,
                    selectedApproval: null,
					approval: null,
					// User Tagged
					tags: [],
					userTagged: [],
					// Additional Attachment
					archiveDocAttachments: [],
					attachmentsEDoc: [],
					additionalAttachments: [],
					attachments: [],
					// Marks
					type: 'Masuk',
					from: 'Eksternal',
					trait: 'Biasa',
					createdAt: null,
					sender: null,
					to: null,
					agendaNumber: null,
					level: 'Biasa',
					takah: null,
					description: null
				},
				validate: {
					agendaNumber: null,
					sender: null,
					title: null,
					docNumber: null,
					duplicateNumber: null,
                    docFileId: null,
					categorySelected: null,
                    userTagged: null,
                },
                submitBtn: {
                    loader: false,
                    text: 'Simpan',
                    disabled: true
				},
				placeholder: 'Pilih lampiran tambahan'
			},
			dispositionForm: {
				input: {
					docId: null,
					selectedReceivers: null,
					receivers: null,
					content: null,
					command: null
				},
				defaultValue: {
					docId: null,
					selectedReceivers: [],
					receivers: [],
					content: null,
					command: null
				},
				validate: {
					receivers: null
				}
			},
			workflowForm: {
                isVerificationOptions: [{label: 'Ya', value: 'yes'}, {label: 'Tidak', value: 'no'}],
                workflowOptions: [],
            },
			traitOptions: ['Biasa', 'Rahasia', 'Sangat Rahasia'],
			fromOptions: ['Internal', 'Eksternal'],
			levelOptions: ['Biasa', 'Kilat', 'Prioritas'],
			docPreviewFileId: null,
			collapse: {
				show: false,
				text: "Tampilkan Deskripsi"
			},
			pdfData: {
				base64Data: null,
				fileName: null,
			},
			lastNoAgenda: []
        }
	},
	computed: {
		currentDate() {
			let date_ob = new Date()
			// adjust 0 before single digit date
			let date = ("0" + date_ob.getDate()).slice(-2)
			// current month
			let month = ("0" + (date_ob.getMonth() + 1)).slice(-2)
			// current year
			let year = date_ob.getFullYear()
			// prints date in YYYY-MM-DD format
			let currentDate = year + "-" + month + "-" + date
			// return currentDate
			return this.$moment(new Date()).format("Y-MM-DD")
		},
		overlayState() {
			return (this.attachmentForm.uploadBtn.loader === true 
				|| this.documentForm.submitBtn.loader === true) ? true : false
		},
		overlayText() {
			return (this.attachmentForm.uploadBtn.loader === true)
				? this.attachmentForm.uploadBtn.text
				: (this.documentForm.submitBtn.loader === true)
					? this.documentForm.submitBtn.text
					: ''
		},
		...mapState('document', {
			uploadProgressBtn: 'uploadProgressBtn'
		})
	},
	watch: {
        emailDocProp: {
            handler: function (newValue, oldValue) {
                this.checkEmailDocProp()
            },
            deep: true,
            imediate: true
		},
		categoryOptionsProp: {
            handler: function (newValue, oldValue) {
				// this.getCategoryOptions()
				this.documentForm.categoryOptions = newValue
            },
            deep: true,
            imediate: true
		},
		tagOptionsProp: {
            handler: function (newValue, oldValue) {
				// this.getTagOptions()
				this.documentForm.receiverOptions = newValue
				this.documentForm.tagOptions = newValue
            },
            deep: true,
            imediate: true
		},
		uploadProgressBtn: {
            handler: function (newValue, oldValue) {
				if (newValue) {
					if (newValue >= 0 && newValue <= 100) this.attachmentForm.uploadBtn.text = 'Memproses... ('+newValue+'%)'
					if (newValue == 100) {
						setTimeout( e => { 
							this.attachmentForm.uploadBtn.text = 'Unggah selesai, mohon menunggu!'
						}, 1000)
					}
				}
            },
            deep: true,
            imediate: true
		},
        
        pdfData: {
            handler: function(newData, oldData) {
				if(newData.base64Data != null) {
					this.attachmentForm.uploadBtn.disabled = false
				}
            },
            deep: true,
            imediate: true
        }
	},
	mounted () {
		var fullUrl = window.location.origin + this.$route.path
		if(fullUrl.includes("pkso.teknusa")) {
			this.baseURL = process.env.VUE_APP_SERVER
		} else {
			this.baseURL = process.env.VUE_APP_SERVER_PROD
		}  
		window.document.addEventListener('validateScanInput', (event) => {
			console.log('event validatescaninput is called')
			console.log(event)

			const scannedFileCount = event.detail.bufferCount
			const filename = event.detail.filename
			this.attachmentForm.input.scannedFileCount = scannedFileCount
			this.attachmentForm.input.filename = filename
			this.validateAttachmentForm()
		})
		window.document.addEventListener('injectScannedFiles', (event) => {
			console.log('event injectScannedFiles is called')
			console.log(event.detail)
			this.injectScannedFiles(event.detail)
		})
		
		// this.getCategoryOptions()
		// this.getTagOptions()
		this.getWorkflowOptions()
		this.getTakahOptions()
        this.documentForm.input.createdAt = this.currentDate
        this.documentForm.defaultValue.createdAt = this.currentDate
	},
    methods: {
		// Back Step
		goBack () {
			this.e1-=1
			if(this.e1 == 1)  {
				this.attachmentForm.uploadBtn.disabled = false
			}
		},
		// Email
		checkEmailDocProp () {
			if (this.emailDocProp != null) {
				const docFileId = this.emailDocProp.docFileId
				this.attachmentForm.input.source = 'Lampiran Surel'
				this.attachmentForm.sourceOptionsDisabled = true
				this.attachmentForm.input.selectedEmailDoc = this.emailDocProp
				this.attachmentForm.input.filename = this.emailDocProp.filename
				this.documentForm.input.docFileId = docFileId
				this.validateAttachmentForm()
			} else {
				this.attachmentForm.input.source = 'Unggah Surat'
				this.attachmentForm.sourceOptionsDisabled = false
				this.attachmentForm.input.selectedEmailDoc = null
				this.documentForm.input.docFileId = null
				this.documentForm.validate.docFileId = null
			}
			this.validateDocumentForm('docFileId')
		},
		// Options
		getCategoryOptions () {
			if (this.categoryOptionsProp && this.categoryOptionsProp.length > 0) {
				this.documentForm.categoryOptions = this.categoryOptionsProp
				return
			}

			this.$store.dispatch({
				type: 'document/getCategory',
				pagination: {
					limit: 100,
					offset: 0
				},
				order: {
					orderType: 'asc'
				}
			}).then( res => {
				let items = []
				res.result.forEach((item) => {
					let rowData = {
						id: item.category_id,
						name: item.category_name,
						label: item.category_name
                    }
					items.push(rowData)
				})
                this.documentForm.categoryOptions = items
			})
		},
		getTagOptions() {
			if (this.tagOptionsProp && this.tagOptionsProp.length > 0) {
				this.documentForm.tagOptions = this.tagOptionsProp
				return
			}

			this.$store.dispatch({
				type: 'user/getUser',
				pagination: {
					limit: 200,
					offset: 0
				},
				order: {
					orderBy: 'fullname',
					orderType: 'asc'
				},
				filter: {
					position: ''
				}
			}).then( res => {
				let items = []
				res.result.forEach((item) => {
					let position = (item.position != null) ? item.position.trim() : ''
					let label =  (position != '') ? item.fullname.trim() +' - '+ position : item.fullname.trim()
                    let rowData = {
						label: label,
						id: item.user_id,
						userId: item.user_id,
                        fullname: item.fullname,
                        email: item.email,
                        rank: item.rank,
						position: item.position
                    }
					items.push(rowData)
				})
				
				this.documentForm.tagOptions = items
			})
		},
		getTakahOptions () {
			this.$store.dispatch({
				type: 'takah/loadData',
				filter: { access: "all" },
				pagination: {
					limit: 200,
					offset: 0
				}
			}).then( res => {
				let items = []
				if (res) {
					res.result.forEach((item) => {
						let rowData = {
							id: item.takah_id,
							name: item.takah_id,
							label: '',
						}
						if (item.takah_no) {
							rowData.label = item.takah_no + '-' + item.takah
						} else {
							rowData.label = '[No Number] -' + item.takah
						}
						items.push(rowData)
					})
				}
				
				this.documentForm.takahOptions = items
			})
		},
		getWorkflowOptions () {
            this.$store.dispatch({
				type: 'workflow/get',
				filter: { isDisposition: true },
				pagination: {
                    limit: 100,
                    offset: 0
                }
			}).then( res => {
				this.workflowForm.workflowOptions = res.result
			}).catch( err => {
				console.log('Failed to fetch disposition workflow data')
				console.log(err)
            })
        },
		// Attachment
		selectSource (selectedSource) {
            // Reset attachment input values
            this.attachmentForm.input.file = null
			this.attachmentForm.input.filename = null
			this.attachmentForm.input.fileBase64 = []
            this.attachmentForm.validate = {
                file: null,
                filename: null
            }
            this.attachmentForm.placeholder = 'Pilih Surat (DOC,DOCX,PDF)'
            this.attachmentForm.uploadBtn = {
                loader: false,
                text: 'Lanjut',
                disabled: true
            }
		},
		upload () {
			if (this.attachmentForm.input.source == 'Pindai Surat') {
				// Call out iframe function getBase64ScannedFiles
				this.proccessTwainScannedFile()
			} else {
				this.uploadDocument()
			}

			this.getLastNoAgenda()
		},

		getLastNoAgenda() {
			this.$store.dispatch({
				type: 'document/getLastNoAgenda',
				formInput: {
					file: this.attachmentForm.input.file,
					filename: this.attachmentForm.input.filename
				}
			}).then( res => {
				this.lastNoAgenda = res.result
			})
		},
		
        // Upload
        uploadDocument () {
			if (this.attachmentForm.input.source == 'Lampiran Surel') {
				this.e1 = 2
				return
			}
			this.attachmentForm.uploadBtn = {
                loader: true,
                text: 'Memproses...',
				disabled: true
			}
			this.$store.dispatch({
				type: 'document/uploadDocument',
				formInput: {
					file: this.attachmentForm.input.file,
					filename: this.attachmentForm.input.filename
				}
			}).then( res => {
				// Inject documentForm docFileId with new created docFile
				const docFileId = res.result.doc_file_id
				this.documentForm.input.docFileId = docFileId
                this.validateDocumentForm("docFileId")
				this.attachmentForm.placeholder = this.attachmentForm.input.filename
				this.e1 = 2
			}).catch( err => {
				this.$toasted.error('Gagal mengunggah surat, silahkan coba kembali').goAway(3000)
            }).finally( () => {
				this.attachmentForm.uploadBtn = {
					loader: false,
					text: 'Lanjut',
					disabled: true
				}
			})
		},
		// Scan
		injectScannedFiles (data) {
			console.log('function injectScannedFiles is called')
			console.log(data)
			
			const base64data = data.base64data
			const filename = data.filename
			this.attachmentForm.input.fileBase64.push(base64data)
			this.attachmentForm.input.filename = filename
			this.uploadDocumentBase64()
		},
		proccessTwainScannedFile() {
			this.attachmentForm.input.fileBase64.push(this.pdfData.base64Data)
			this.attachmentForm.input.filename = this.pdfData.fileName
			this.uploadDocumentBase64()
		},
		uploadDocumentBase64 () {
			if (this.attachmentForm.input.fileBase64.length == 0) {
				console.log('file base64 attachment is empty')
				return
			}

			this.attachmentForm.uploadBtn = {
                loader: true,
                text: 'Memproses...',
				disabled: true
			}
			let filename = this.attachmentForm.input.filename
			filename += (filename.indexOf('.docx') == -1 || filename.indexOf('.pdf') == -1) ? '.pdf' : ''
			this.$store.dispatch({
				type: 'document/uploadDocumentBase64',
				formInput: {
					pdfBase64: this.attachmentForm.input.fileBase64[0],
					filename: filename
				}
			}).then( res => {
				// Inject documentForm docFileId with new created docFile
				const docFileId = res.result.doc_file_id
				this.documentForm.input.docFileId = docFileId
                this.validateDocumentForm("docFileId")
				this.attachmentForm.placeholder = this.attachmentForm.input.filename
				this.e1 = 2
			}).catch( err => {
				this.$toasted.error('Gagal mengunggah surat, silahkan coba kembali').goAway(3000)
            }).finally( () => {
				this.attachmentForm.uploadBtn = {
					loader: false,
					text: 'Memproses...',
					disabled: true
				}
			})
		},
		loadPreview () {
			this.docPreviewFileId = this.documentForm.input.docFileId
			this.e1 += 1
		},
		// Workflow
        selectWorkflowOption (selectedOption) {
            this.documentForm.input.isVerification = selectedOption.value
            this.documentForm.tagOptions = this.documentForm.receiverOptions
            this.documentForm.input.selectedVerification = null
            this.dispositionForm.input.selectedReceivers = null
            this.validateDocumentForm('receivers')
		},
		filterTagOptions (userIds) {
            let selectedTagOptions = this.documentForm.input.userTagged
            let tagOptions = this.documentForm.receiverOptions

            for (let i = 0; i < userIds.length; i++) {
                const userId = userIds[i];
                // Exclude selected tag user if same with selected workflow/direct users
                let filteredSelectedTagOptions = selectedTagOptions.filter(function( obj ) {
                    return obj.id !== userId
                })
                selectedTagOptions = filteredSelectedTagOptions

                // Exclude available tag user if same with selected workflow/direct users
                let filteredTagOptions = tagOptions.filter(function( obj ) {
                    return obj.id !== userId
                })
                tagOptions = filteredTagOptions
            }
            this.documentForm.input.userTagged = selectedTagOptions
            this.documentForm.tagOptions = tagOptions
        },
        selectWorkflow (selectedVerification) {
            this.documentForm.input.verification = selectedVerification.workflow_config
            this.validateDocumentForm('receivers')
            let workflowUserIds = []
            for (let i = 0; i < selectedVerification.flow.length; i++) {
                const flowProp = selectedVerification.flow[i]
                const userId = flowProp.user_id
                workflowUserIds.push(userId)
            }
            this.filterTagOptions(workflowUserIds)
        },
		selectReceiver (selectedReceiver) {
			this.validateDocumentForm('receivers')
            this.filterTagOptions([selectedReceiver.userId])
		},
		// Additional Attachment - Archive Doc
		extrachArchiveDocAttachments () {
			let docFileIds = []
			let archiveDocAttachments = this.documentForm.input.archiveDocAttachments
			if (archiveDocAttachments.length > 0) {
				for (let i = 0; i < archiveDocAttachments.length; i++) {
					const archiveDoc = archiveDocAttachments[i];
					docFileIds.push(archiveDoc.doc_file_id)
				}
			}
			return docFileIds
		},
		// Additional Attachment - Upload
		additionalAttachmentChange (event) {
			let fileNumber = event.length
			if (event && fileNumber > 0) {
				let additionalAttachments = []
				for (let i = 0; i < event.length; i++) {
					const fileProp = event[i];
					let data = {
						filename: fileProp.name,
						file: fileProp
					}
					additionalAttachments.push(data)
				}
				this.documentForm.input.additionalAttachments = additionalAttachments
				this.documentForm.placeholder = fileNumber +' surat telah dipilih'
			}
		},
		uploadAdditionalAttachment (selectedIndex) {
			return new Promise( (resolve, reject) => {
				let additionalAttachment = this.documentForm.input.additionalAttachments[selectedIndex]
				let formInput = {
					filename: additionalAttachment.filename,
					file: [additionalAttachment.file]
				}
				this.$store.dispatch({
					type: 'document/uploadDocument',
					formInput: formInput
				}).then( res => {
					resolve(res.result)
				}).catch( err => {
					reject(err)
				})
			})
		},
		// Document
		storeDocument (isUpdate = false) {
			// Extract values form multiple inputs
			// Category
            let categorySelected = this.documentForm.input.categorySelected
			this.documentForm.input.categoryId = categorySelected.id
			// User Tags
			let tags = []
			let userTagged = this.documentForm.input.userTagged
			for (let i = 0; i < userTagged.length; i++) {
				let userId = userTagged[i].userId
				tags.push(userId)
			}
			this.documentForm.input.tags = tags
			let storeType = (isUpdate)
				? 'document/updateDocument' : 'document/storeDocument'

			// console.log(this.documentForm.input)

			return new Promise( (resolve, reject) => {
				this.$store.dispatch({
					type: storeType,
					formInput: this.documentForm.input
				}).then( res => {
					resolve(res.result)
				}).catch( err => {
					reject(err)
				})
			})
		},
		storeDisposition () {
			// Disposition Receivers
			let receivers = []
			let selectedReceivers = this.dispositionForm.input.selectedReceivers
			receivers.push(selectedReceivers.userId)
			this.dispositionForm.input.receivers = receivers

			return new Promise( (resolve, reject) => {
				this.$store.dispatch({
					type: 'disposition/store',
					formInput: this.dispositionForm.input
				}).then( res => {
					resolve(res.result)
				}).catch( err => {
					reject(err)
				})
			})
		},
        async saveItem () {
			// Begin the save process
			this.documentForm.submitBtn = {
                loader: true,
                text: 'Memproses...',
				disabled: true
			}
			// Upload additional attachments
			let additionalAttachments = this.documentForm.input.additionalAttachments
			if (additionalAttachments.length > 0) {
				if (!this.saveState.attachment) {
					try {
						let docFileIds = []
						for (let i = 0; i < additionalAttachments.length; i++) {
							let docFileProp = await this.uploadAdditionalAttachment(i)
							let docFileId = docFileProp.doc_file_id
							docFileIds.push(docFileId)
						}
						this.saveState.attachment = true
						this.documentForm.input.attachments = docFileIds
					} catch (err) {
						this.saveState.attachment = false
						this.$toasted.error('Proses unggah lampiran tambahan gagal, silahkan hubungi administrator').goAway(3000)
						this.documentForm.submitBtn = {
							loader: false,
							text: 'Simpan',
							disabled: true
						}
						return false
					}
				}
			}
			// Additional Attachment Archive Documents
			let archiveDocAttachments = this.extrachArchiveDocAttachments()
			this.documentForm.input.attachmentsEDoc = archiveDocAttachments

			// Store document
			if (!this.saveState.document) {
				try {
					let documentProp = await this.storeDocument()
					this.saveState.document = true
					this.dispositionForm.input.docId = documentProp[0].doc_id
				} catch (err) {
					console.log('Failed to store document')
					console.log(err)
					this.saveState.document = false
				}
			}
			// Store disposition
			this.saveState.disposition = true
			const isVerification = this.documentForm.input.isVerification
			if (this.saveState.document && isVerification == "no") {
				try {
					let dispositionProp = await this.storeDisposition()
					this.saveState.disposition = true
				} catch (err) {
					console.log('Failed to store disposition')
					console.log(err)
					this.saveState.disposition = false
				}
			}
			// Finishing the successful save process
			if (this.saveState.document && this.saveState.disposition) {
				this.$toasted.success('Sukses menambah data').goAway(3000)
				this.closePopupModal(true)
			} else {
				this.$toasted.error('Gagal menambah surat masuk baru, harap hubungi administrator').goAway(3000)
				this.documentForm.submitBtn = {
					loader: false,
					text: 'Simpan',
					disabled: true
				}
			}
        },
        // Navigation & Actions
		closePopupModal (refreshState = false) {
			// Reset Stepper
			this.e1 = 1
            // Reset Attachment Form
			this.attachmentForm.input.source = 'Unggah Surat'
			this.attachmentForm.input.selectedEmailDoc = null
			this.attachmentForm.input.file = null
			this.attachmentForm.input.filename = null
            this.attachmentForm.validate = {
				file: null,
                filename: null
            }
            this.attachmentForm.placeholder = 'Pilih Surat (DOC,DOCX,PDF)'
            this.attachmentForm.uploadBtn = {
                loader: false,
                text: 'Lanjut',
                disabled: true
            }
			// Reset Document Form
			this.documentForm.tagOptions = this.documentForm.receiverOptions
            this.documentForm.input = Object.assign({}, this.documentForm.defaultValue)
            this.documentForm.validate = {
				title: null,
                docNumber: null,
                docFileId: null,
                categorySelected: null,
                userTagged: null
			}
			this.documentForm.placeholder = 'Pilih lampiran tambahan'
			// Reset Disposition Form
			this.dispositionForm.input = Object.assign({}, this.dispositionForm.defaultValue)
			this.dispositionForm.validate = {
				receivers: false
			}
			// Reset save states
			this.saveState = {
				attachment: false,
				document: false,
				disposition: false
			}
            this.documentForm.submitBtn = {
                loader: false,
                text: 'Simpan',
                disabled: true
			}
			const currRouteName = this.$route.name
			refreshState = (currRouteName !== 'Surat Masuk') ? false : refreshState
            this.$emit('close-popup-modal', refreshState)
		},
        // Validation
        validateAttachmentForm (event = null) {
			if (this.attachmentForm.input.source == 'Lampiran Surel') {
				this.attachmentForm.uploadBtn.disabled = false
				return
			}

			if (this.attachmentForm.input.source == 'Pindai Surat') {
				if (this.attachmentForm.input.scannedFileCount > 0) {
					this.attachmentForm.validate.file = true
				} else {
					this.attachmentForm.validate.file = false
				}
			} else {
				if (event && event.length > 0) {
					let fileCollection = []
					event.forEach((item) => {
						fileCollection.push(item.name)
					})
					this.attachmentForm.placeholder = fileCollection.join()
					this.attachmentForm.input.file = event
					let filename = fileCollection.join().replace(' ','_')
					this.attachmentForm.input.filename = filename
					this.attachmentForm.validate.file = true
				} else {
					this.attachmentForm.placeholder = 'Belum memilih surat'
					this.attachmentForm.validate.file = false
				}	
			}
			
			if (this.attachmentForm.input.filename != '') {
				this.attachmentForm.validate.filename = true
			} else {
				this.attachmentForm.validate.filename = false
			}
			
            if (
				this.attachmentForm.validate.file &&
				this.attachmentForm.validate.filename
			) {
				this.attachmentForm.uploadBtn.disabled = false
			} else {
				this.attachmentForm.uploadBtn.disabled = true
			}
		},
		validateDocumentForm (field, event = null) {
			if (field == 'agendaNumber') {
				if (this.documentForm.input.agendaNumber != '') {
                    this.documentForm.validate.agendaNumber = true
				} else {
                    this.documentForm.validate.agendaNumber = false
				}
			}

			if (field == 'sender') {
				if (this.documentForm.input.sender != '') {
                    this.documentForm.validate.sender = true
				} else {
                    this.documentForm.validate.sender = false
				}
			}

			if (field == 'title') {
				if (this.documentForm.input.title != '') {
                    this.documentForm.validate.title = true
				} else {
                    this.documentForm.validate.title = false
				}
			}
			
			if (field == 'docNumber') {
				console.log('Perubahan!');
				if (this.documentForm.input.docNumber != '') {
					this.validateDocumentNumber(this.documentForm.input.docNumber)
						.then(res => {
							if(res.result) {
								this.documentForm.validate.docNumber = false
								this.documentForm.validate.duplicateNumber = true
							} else {
								this.documentForm.validate.docNumber = true
								this.documentForm.validate.duplicateNumber = false
							}
						})
				} else {
                    this.documentForm.validate.docNumber = false
				}
			}

			if (field == 'docFileId') {
				if (this.documentForm.input.docFileId != null) {
					this.documentForm.validate.docFileId = true
				} else {
					this.documentForm.validate.docFileId = false
				}
			}

			if (field == 'categorySelected') {
				if (this.documentForm.input.categorySelected != '') {
					this.documentForm.validate.categorySelected = true
				} else {
					this.documentForm.validate.categorySelected = false
				}
            }
			
			if (field == 'receivers') {
				const selectedReceiver = (this.documentForm.input.isVerification == 'no')
					? this.dispositionForm.input.selectedReceivers
					: this.documentForm.input.selectedVerification
					
				if (selectedReceiver != null && selectedReceiver != '') {
					this.dispositionForm.validate.receivers = true
				} else {
					this.dispositionForm.validate.receivers = false
				}
			}

			if (field == 'trait') this.documentForm.input.trait = event
			if (field == 'from') this.documentForm.input.from = event
			if (field == 'level') this.documentForm.input.level = event

			if (
                this.documentForm.validate.agendaNumber &&
                this.documentForm.validate.sender &&
                this.documentForm.validate.title &&
				this.documentForm.validate.docNumber &&
                this.documentForm.validate.docFileId &&
				this.documentForm.validate.categorySelected &&
				this.dispositionForm.validate.receivers
            ) {
                this.documentForm.submitBtn.disabled = false
            } else {
                this.documentForm.submitBtn.disabled = true
			}
		},
		validateDocumentNumber (item) {
			// console.log(item)
			return new Promise( (resolve, reject) => {
				this.$store.dispatch({
					type: 'document/isExist',
					filter: {
						docNo: item
					}
				}).then( res => {
					resolve(res.result)
				}).catch( err => {
					reject(err)
				})
			})	
		}
    }
}
</script>