<template>
    <multiselect
        id="archive-doc"
        :allow-empty="true"
        :clear-on-select="true"
        :close-on-select="false"
        :internal-search="false"
        :loading="loading"
        :maxHeight="300"
        :multiple="true"
        :options="options"
        :options-limit="50"
        :searchable="true"
        :show-no-results="true"
        :value="value"
        deselectLabel="Tekan 'enter' untuk menghapus"
        label="title"
        openDirection="top"
        placeholder="Ketik Prihal Surat untuk mulai pencarian surat (min. 3 karakter)"
        selectLabel="Tekan 'enter' untuk memilih"
        selectedLabel="Terpilih"
        track-by="doc_file_id"
        @input="changeValue(doc_file_id)"
        @search-change="findDocument">
        <span slot="noResult">{{ this.noResultLabel }}</span>
    </multiselect>
</template>

<script>
export default {
    props: ['value'],
    data () {
        return {
            loading: false,
            options: [],
            noResult: false,
            noResultLabel: 'Menunggu memasukkan kata kunci pencarian...',
        }
    },
    watch: {
        valueProp (val) {
            console.log('changed value for archive attachment')
            console.log(val)
        }
    },
    methods: {
        findDocument (query) {
			if (query.length < 3) {
				this.options = []
				this.noResultLabel = 'Menunggu memasukkan kata kunci pencarian...'
				this.noResult = true
				return false
			}

            this.loading = true
			return new Promise((resolve, reject) => {
				this.$store.dispatch({
					type: 'document/getDocument',
					pagination: {
						limit: 100,
						offset: 0
					},
					filter: {
                        type: 'Semua',
                        isPublish: true,
                        title: query
                    }
				}).then(res => {
					if (!res.result || res.result.length == 0) {
						this.options = []
						this.noResultLabel = 'Surat tidak ditemukan. Mohon unutk memasukkan kata kunci pencarian lain'
						this.noResult = true
					} else {
                        this.noResult = false                        
                        let options = []
                        let data = res.result
                        for (let i = 0; i < data.length; i++) {
                            const element = data[i];
                            let prop = {
                                doc_id: element.doc_id,
                                doc_no: element.doc_no,
                                title: element.title + " - " + element.doc_no ,
                                doc_file_id: element.doc_file_id
                            }
                            options.push(prop)
                        }
						this.options = options
					}
				}).catch(err => {
					this.options = []
					this.noResultLabel = 'Surat tidak ditemukan. Mohon unutk memasukkan kata kunci pencarian lain'
					this.noResult = true
				}).finally(() => {
					this.loading = false
				})
			})
        },
        changeValue (value) {
            this.$emit('input', value)
        }
    }
}
</script>