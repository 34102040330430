<template>
<div class="dashboard-container">
	<v-app>
		<!-- Dashboard Header -->
		<div class="header-container">
			<!-- Create Document Action -->
			<div class="flex-grow-0 p-0">
				<create-document-option-button-modal
					v-on:show-incoming-document-modal="showIncomingDocumentModal"
					v-on:show-outgoing-document-modal="showOutgoingDocumentModal"
					v-on:show-outgoing-document-ready-to-send-modal="showOutgoingDocumentReadyToSendModal">
				</create-document-option-button-modal>
			</div>
			<!-- Search Box -->
			<div class="flex-grow-1 ml-1 mb-1">
				<document-search-box :title-type-prop="isTitle"></document-search-box>
			</div>
			<div class="flex-grow-0 p-0">
				<CButton @click="setTitle()" color="danger" size="m" variant="outline">
					Cari {{searchType}}
				</CButton>
			</div>
		</div>
		<hr class="bottom-devider"/>
		<!-- Dashboard Body -->
		<div class="body-container">
			<!-- Summary & Hyperlink -->
			<CRow>
				<CCol col="12" sm="6" lg="3" @click="redirect('/document_archive')">
					<CWidgetIcon header="Arsip" class="clickable" color="primary">
						<h4 class="widget-content">{{ documentSummary.archive }}</h4>
					</CWidgetIcon>
				</CCol>
				<CCol col="12" sm="6" lg="3" @click="redirect('/document_in')">
					<CWidgetIcon header="Surat Masuk" class="clickable" color="success">
						<h4 class="widget-content">{{ documentSummary.in }}</h4>
					</CWidgetIcon>
				</CCol>
				<CCol col="12" sm="6" lg="3" @click="redirect('/document_out')">
					<CWidgetIcon header="Surat Keluar" class="clickable" color="danger">
						<h4 class="widget-content">{{ documentSummary.out }}</h4>
					</CWidgetIcon>
				</CCol>
				<CCol col="12" sm="6" lg="3" @click="redirect('/favorite')">
					<CWidgetIcon header="Favorit" class="clickable" color="warning">
						<h4 class="widget-content">{{ documentSummary.favorite }}</h4>
					</CWidgetIcon>
				</CCol>
			</CRow>
			<!-- Widget -->
			<CRow>
				<!-- Approval -->
				<CCol col="6">
					<CCard class="p-0">
						<CCardHeader>
							<div class="d-flex">
								<div class="flex-grow-1">
									<b class="mb-0" style="line-height:2rem"><font-awesome-icon icon="history" class="mr-10"/> Pengajuan Terbaru</b>
								</div>
							</div>
						</CCardHeader>
						<CCardBody>
							<v-data-table
								dense
								:loading="approvalTable.loading"
								loading-text="Memuat Data..."
								:headers="approvalTable.headers"
								height=260
								:options.sync="approvalOptionsTable"
								:server-items-length="approvalTable.totalData"
								:multi-sort="false"
								:items="approvalTable.dataList"
								:footer-props="approvalTable.footerProps"
								class="elevation-1">
								<template v-slot:item="{ item }">
									<tr class="clickable" @click="redirect('/document_out/detail/'+ item.docId)">
										<td>
											<span class="font-xs">{{ item.perihal }}</span>
										</td>
										<td width="120px">
											<span class="font-xs">{{ item.diajukan_oleh.position }}</span>
										</td>
										<td width="120px" class="text-center">
											<span class="font-xs">{{ item.diajukan_pada }}</span>
										</td>
									</tr>
								</template>
								<template v-slot:no-data>
									<span>Tidak Ada Aktivitas Terkini</span>
								</template>
							</v-data-table>
						</CCardBody>
					</CCard>
				</CCol>
				<!-- Revision -->
				<CCol col="6">
					<CCard class="p-0">
						<CCardHeader>
							<div class="d-flex">
								<div class="flex-grow-1">
									<b class="mb-0" style="line-height:2rem"><font-awesome-icon icon="history" class="mr-10"/> Revisi Terbaru</b>
								</div>
							</div>
						</CCardHeader>
						<CCardBody>
							<v-data-table
								dense
								:loading="revisionTable.loading"
								loading-text="Memuat Data..."
								:headers="revisionTable.headers"
								height=260
								:options.sync="revisionOptionsTable"
								:server-items-length="revisionTable.totalData"
								:multi-sort="false"
								:items="revisionTable.dataList"
								:footer-props="revisionTable.footerProps"
								class="elevation-1">
								<template v-slot:item="{ item }">
									<tr class="clickable" @click="redirect('/document_out/detail/' + item.docId)">
										<td>
											<div class="text-ellipsis font-xs">{{ item.title }}</div>
										</td>
										<td>
											<div v-if="item.comments != undefined" class="text-ellipsis font-xs">{{ item.comments[0].user.fullname }}</div>
										</td>
										<td width="120px">
											<div class="font-xs">{{ item.created_at }}</div>
										</td>
									</tr>
								</template>
								<template v-slot:no-data>
									<span>Tidak Ada Aktivitas Terkini</span>
								</template>
							</v-data-table>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</div>
		<!-- Incoming Document Popup Modal -->
		<incoming-document-form
			v-bind:popup-modal-prop="modalIncomingDocument"
			v-bind:category-options-prop="categoryOptions"
			v-bind:tag-options-prop="userTagOptions"
			v-on:close-popup-modal="closeIncomingDocumentModal">
		</incoming-document-form>
		<!-- Outgoing Document Popup Modal -->
		<outgoing-document-form
			v-bind:popup-modal-prop="modalOutgoingDocument"
			v-bind:category-options-prop="categoryOptions"
			v-bind:tag-options-prop="userTagOptions"
			v-bind:ready-send-prop="false"
			v-on:close-popup-modal="closeOutgoingDocumentModal">
		</outgoing-document-form>
		<outgoing-document-form
			v-bind:popup-modal-prop="modalOutgoingDocumentReadyToSend"
			v-bind:category-options-prop="categoryOptions"
			v-bind:tag-options-prop="userTagOptions"
			v-bind:ready-send-prop="true"
			v-on:close-popup-modal="closeOutgoingDocumentModal">
		</outgoing-document-form>
	</v-app>
</div>
</template>

<script>
import {
    createNamespacedHelpers,
    mapState,
    mapGetters
} from 'vuex'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import CreateDocumentOption from '@/components/document/CreateDocumentOptionButtonModal.vue'
import DocumentSearchBox from '@/components/document/SearchBox.vue'
import IncomingDocumentForm from '@/components/document/IncomingDocument.vue'
import OutgoingDocumentForm from '@/components/document/OutgoingDocument.vue'

import { CChartPie, CChartHorizontalBar } from '@coreui/vue-chartjs'

export default {
    names: 'Dashboard',

	data () {
		const modalCreateDoc = {
			isShow: false,
			modalTitle: 'Susun Surat Baru'
		}
		const modalIncomingDocument = {
			isShow: false
		}
		const modalOutgoingDocument = {
			isShow: false
		}
		const modalOutgoingDocumentReadyToSend = {
			isShow: false
		}
		const modalCreateTemplate = {
			isShow: false,
			modalTitle: 'Susun Surat dari Template',
			formFile: {
				dataList: [],
				input: {
					docTemplate: null
				},
				defaultValue: {
					docTemplate: []
				},
				validate: {
					docTemplate: null
				}
			},
			form: {
				input: {
					title: null,
					docNumber: null,
					categoryId: null,
					categorySelected: null,
					docFileId: null,
					isWorkflow: 'no',
					tags: null,
					userTagged: null,
					attachment: null,
					type: 'Masuk',
					from: 'Internal',
					trait: 'Biasa'
				},
				defaultValue: {
					title: '',
					docNumber: '',
					categoryId: '',
					categorySelected: '',
					docFileId: '',
					isWorkflow: 'no',
					tags: [],
					userTagged: [],
					attachment: [],
					type: '',
					from: '',
					trait: ''
				},
				validate: {
					title: null,
					docNumber: null,
					categorySelected: null,
					docFileId: null,
					attachment: null
				}
			},
			submitBtn: {
                loader: false,
                text: 'Simpan',
				disabled: true
			},
			previewImg: '',
			previewLoader: false
		}
		const documentSummary = {
			loading: false,
			total: 0,
			in: 0,
			out: 0,
			favorite: 0,
			archive: 0,
		}
		const approvalTable = {
			loading: true,
			headers: [
				{ text: 'Perihal', value: 'perihal', align: 'left', sortable: true },
                { text: 'Diajukan Oleh', value: 'diajukan_oleh.position', align: 'left', sortable: true },
                { text: 'Diajukan Pada', value: 'diajukan_pada', align: 'center', sortable: false }
			],
			footerProps: {
				'disable-items-per-page': true,
				'items-per-page-text': ' '
			},
			totalData: 0,
			dataList: []
		}
		const revisionTable = {
			loading: true,
			headers: [
				{ text: 'Perihal', value: 'title', align: 'left', sortable: true },
                { text: 'Diajukan Oleh', value: 'comments[0].user.fullname', align: 'left', sortable: true },
                { text: 'Diajukan Pada', value: 'created_at', align: 'center', sortable: false }
			],
			footerProps: {
				'disable-items-per-page': true,
				'items-per-page-text': ' '
			},
			totalData: 0,
			dataList: []
		}

		const summaryFilter = {
			startDate: null,
			endDate: null,
			limit: 5,
			showFilter: false
		}
		return {
			loading: true,
			// Options
			typeOptions: ['Masuk', 'Keluar'],
			fromOptions: ['Internal', 'Eksternal'],
			traitOptions: ['Biasa', 'Rahasia', 'Sangat Rahasia'],
			categoryOptions: [],
			userTagOptions: [],
			// Forms
			modalCreateDoc,
			modalIncomingDocument,
			modalOutgoingDocument,
			modalOutgoingDocumentReadyToSend,
			modalCreateTemplate,
			// Widgets
			documentSummary,
			approvalTable,
            approvalOptionsTable: {},
			revisionTable,
            revisionOptionsTable: {},
			pieChartLabel: ['Surat Selesai', 'Surat Belum Selesai'],
			pieChartColorLabel: ['#3399ff', '#E55353'],
			dataSetsDocumentIn: [],
			dataSetsDocumentOut: [],
			dataSetsDocumentNotaDinas: [],
			dataSetsLastProcess: [],
			pieChartDataDocumentIn: {
				data: [],
				backgroundColor: []
			},
			pieChartDataDocumentOut: {
				data: [],
				backgroundColor: []
			},
			pieChartDataDocumentNotaDinas: {
				data: [],
				backgroundColor: []
			},
			barChartDataLastProcess: {
				data: [],
				label: 'Kecepatan Proses Data (Dalam Detik)',
				backgroundColor: '#E55353'
			},
			labelLastProccess: [],
			percentageDone: 0,
			percentageActive: 0,
			isTitle: false,
			searchType: 'OCR dalam Dokumen',
			summaryFilter
		}
	},
	computed: {
		...mapState('auth', {
			loggedUserId: 'user_id',
			loggedUserPosition: 'position'
		}),
		docTemplate() {
			return this.modalCreateTemplate.formFile.input.docTemplate
		},
		categoryCreateTemplateSelected() {
			return this.modalCreateTemplate.form.input.categorySelected
		},
        ...mapState('document', {
			uploadProgressBtn: 'uploadProgressBtn'
		})
	},
	watch: {
        docTemplate(){
            this.validateCreateTemplateForm('docTemplate')
		},
        categoryCreateTemplateSelected(){
            this.validateCreateTemplateForm('categorySelected')
		},
		revisionOptionsTable: {
            deep: true,
            handler () {
                this.getRevisionList()
            }
        },
		approvalOptionsTable: {
			deep: true,
            handler () {
                this.getApprovalList()
            }
		}
    },
	mounted () {
		// Options
        this.getDocTemplateOptions()
        this.getCategoryOptions()
		this.getUserTagOptions()
		// Widgets
		this.getDocumentSummary()
		this.getApprovalList()
		this.getRevisionList()
		this.getSummaryDoc()
		this.getLastProccess()
		this.setTitle()

		this.getTwainApiKey()
	},
	methods: {
		getTwainApiKey () {
            this.$store.dispatch({
					type: 'scan/getApiKey',
				}).then(res => {
					localStorage.removeItem("twainToken")
					localStorage.setItem("twainToken", res.result.token)
				})
        },
		// Options
		getCategoryOptions () {
			this.$store.dispatch(
				{
					type: 'document/getCategory',
					pagination: {
						limit: 100,
						offset: 0
					},
					order: {
						orderType: 'asc'
					}
				}
			).then( res => {
				let items = []
				if (res) {
					res.result.forEach((item) => {
						let rowData = {
							id: item.category_id,
							name: item.category_name,
							label: item.category_name
						}
						items.push(rowData)
					})
				}
				
                this.categoryOptions = items
			})
		},
		getUserTagOptions () {
			this.$store.dispatch(
				{
					type: 'user/getUser',
					pagination: {
						limit: 100000,
						offset: 0
					},
					order: {
                        orderBy: 'fullname',
                        orderType: 'asc'
                    },
                    filter: {
						isActive: true
                    }
				}
			).then( res => {
				let items = []
				if (res) {
					for (let i = 0; i < res.result.length; i++) {
						const item = res.result[i];
						let position = (item.position != null) ? item.position : ''
						let positionExisted = items.some(el => el.position === position)
						if ((item.position != this.loggedUserPosition) && !positionExisted) {
							let label = position.trim()
							let rowData = {
								label: label,
								id: item.user_id,
								userId: item.user_id,
								fullname: item.fullname,
								email: item.email,
								rank: item.rank,
								position: item.position,
								command: null
							}
							items.push(rowData)
						}
					}
				}
				
				this.userTagOptions = items
			})
		},
		getDocTemplateOptions () {
			this.$store.dispatch(
				{
					type: 'document/getMyDocument',
					pagination: {
						limit: 100,
						offset: 0
					}
				}
			).then( res => {
				let items = []
				if (res) {
					res.result.forEach((item) => {
						let rowData = {
							id: item.input_id,
							name: item.filename
						}
						items.push(rowData)
					})
				}
				
                this.modalCreateTemplate.formFile.dataList = items
			})
		},
		// Document Choice Modal
		showCreateDocModal () {
			this.modalCreateDoc.isShow = true
		},
		closeCreateDocModal () {
			this.modalCreateDoc.isShow = false
		},
		// Incoming Document
		showIncomingDocumentModal () {
			this.modalIncomingDocument.isShow = true
		},
		closeIncomingDocumentModal (refreshState) {
			this.modalIncomingDocument.isShow = false
			if (refreshState)
				this.getDocumentSummary()
		},
		// Outgoing Document
		showOutgoingDocumentModal () {
			this.modalOutgoingDocument.isShow = true
		},
		showOutgoingDocumentReadyToSendModal () {
			this.modalOutgoingDocumentReadyToSend.isShow = true
		},
		closeOutgoingDocumentModal (refreshState) {
			this.modalOutgoingDocument.isShow = false
			this.modalOutgoingDocumentReadyToSend.isShow = false
			if (refreshState)
				this.getDocumentSummary()
		},
		// Summary Widget
		getDocumentSummary () {
			this.documentSummary.loading = true
			this.$store.dispatch({
				type: 'document/getSummary'
			}).then(res => {
				if (res) {
					let result = res.result
					this.documentSummary = {
						total: result.semua_document,
						in: result.document_masuk,
						out: result.document_keluar,
						favorite: result.document_favorit,
						archive: result.document_arsip
					}
				}
			}).finally(() => {
				this.documentSummary.loading = false
			})
		},
		// Approval Widget
		getApprovalList () {
            const { sortBy, sortDesc, page, itemsPerPage } = this.revisionOptionsTable
			this.approvalTable.loading = true
			return new Promise((resolve, reject) => {
				this.$store.dispatch({
					type: 'document/getApprovalSubmission',
					pagination: {
                        limit: itemsPerPage,
                        offset: (page-1)*itemsPerPage
					},
					userId: this.loggedUserId
				}).then( res => {
					if (res) {
						this.approvalTable.dataList = res.result
						this.approvalTable.totalData = res.result.total_count
					}
				}).catch ( err => {
					console.log(err)
                }).finally( () => {
					this.approvalTable.loading = false
				})
			})
		},
		// Revision Widget
		getRevisionList () {
            const { sortBy, sortDesc, page, itemsPerPage } = this.revisionOptionsTable
            this.revisionTable.loading = true
			return new Promise((resolve, reject) => {
                this.$store.dispatch({
					type: 'revision/get',
					pagination: {
                        limit: itemsPerPage,
                        offset: (page-1)*itemsPerPage
					}
				}).then( res => {
					let items = []
					if (res) {
						res.result.forEach( item => {
							let rowData = item
							rowData.created_at = this.$moment(item.created_at).format("Y-MM-DD HH:mm")
							items.push(rowData)
						})
						this.revisionTable.totalData = res.total_count
					}
					this.revisionTable.dataList = items
				}).catch( err => {
					// this.$toasted.error('Gagal memuat data revisi').goAway(3000)
					console.log()
                }).finally( () => {
					this.revisionTable.loading = false
				})
			})
		},
		redirect (routePath) {
			console.log(routePath)
			if(this.$route.path != routePath){
				this.$router.push(routePath)
			}
		},
		getSummaryDoc() {  
			this.$store.dispatch({
				type: 'summary/getDocSummary',
				filter: this.summaryFilter
			}).then( res => {
				var result = res.result

				if(result.surat_masuk != undefined) {
					this.dataSetsDocumentIn = []
					this.pieChartDataDocumentIn.data = []
					this.pieChartDataDocumentIn.data.push(result.surat_masuk.surat_selesai)
					this.pieChartDataDocumentIn.data.push(result.surat_masuk.surat_aktif)
					this.pieChartDataDocumentIn.backgroundColor = this.pieChartColorLabel
					this.dataSetsDocumentIn.push(this.pieChartDataDocumentIn)
				}

				
				if(result.surat_keluar != undefined) {
					this.dataSetsDocumentOut = []
					this.pieChartDataDocumentOut.data = []
					this.pieChartDataDocumentOut.data.push(result.surat_keluar.surat_selesai)
					this.pieChartDataDocumentOut.data.push(result.surat_keluar.surat_aktif)
					this.pieChartDataDocumentOut.backgroundColor = this.pieChartColorLabel
					this.dataSetsDocumentOut.push(this.pieChartDataDocumentOut)
				}

				
				if(result.surat_nodin != undefined) {
					this.dataSetsDocumentNotaDinas = []
					this.pieChartDataDocumentNotaDinas.data = []
					this.pieChartDataDocumentNotaDinas.data.push(result.surat_nodin.surat_selesai)
					this.pieChartDataDocumentNotaDinas.data.push(result.surat_nodin.surat_aktif)
					this.pieChartDataDocumentNotaDinas.backgroundColor = this.pieChartColorLabel
					this.dataSetsDocumentNotaDinas.push(this.pieChartDataDocumentNotaDinas)
				}
			
				if(result.semua_surat != undefined) {
					this.percentageDone = Math.round((result.semua_surat.surat_selesai / result.semua_surat.total_surat) * 100)
					this.percentageActive = 100 - this.percentageDone

					document.getElementById("progressBarDone").style.width = this.percentageDone+"%"
					document.getElementById("progressBarActive").style.width = this.percentageActive+"%"
				}

			}).catch( err => {
			})
		},
		getLastProccess(){
			this.$store.dispatch({
				type: 'summary/getLastProcess',
				filter: this.summaryFilter
			}).then( res => {
				var result = res.result
				this.dataSetsLastProcess = []
				this.labelLastProccess = []
				this.barChartDataLastProcess.data = []
				if(result != undefined) {
					if(result.length > 0) {
						result.forEach(data => {

							this.barChartDataLastProcess.data.push(data.duration)
							this.labelLastProccess.push(data.title)
						})
					}
				}
				this.dataSetsLastProcess.push(this.barChartDataLastProcess)

			}).catch( err => {
				// this.$toasted.error('Gagal memuat data revisi').goAway(3000)
				console.log()
			})

		},
		getSummaryProccess(){
			this.getSummaryDoc()
			this.getLastProccess()
		},
		setTitle(){
			if (this.isTitle == false) {
				this.isTitle = true
				this.searchType = 'Judul'
			} else {
				this.isTitle = false
				this.searchType = 'OCR dalam Dokumen'
			}
		}
	},
    components: {
		'create-document-option-button-modal': CreateDocumentOption,
		'document-search-box': DocumentSearchBox,
        'incoming-document-form': IncomingDocumentForm,
        'outgoing-document-form': OutgoingDocumentForm,
    }
}
</script>

<style scoped lang="scss">
#app {
	background-color: #f5f5f5;
}
.mt-10 {
	margin-top: 10px !important;
}
.mr-10 {
	margin-right: 10px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.ml-10 {
	margin-left: 10px !important;
}
.widget-content {
	line-height: 1.7;
	margin: 0px;
	min-height: 35px;
	min-width: 35px;
	text-align: center !important;
}

.border-right {
	border-right: solid black 1px;
}
</style>
