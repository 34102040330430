<template>
    <multiselect
        id="searchBox"
        :clear-on-select="true"
        :close-on-select="true"
        :hide-selected="true"
        :internal-search="false"
        :loading="searchBox.loading"
        :maxHeight="500"
        :options="searchBox.options"
        :options-limit="50"
        :searchable="true"
        :show-no-results="true"
        deselectLabel="Tekan 'enter' untuk menghapus"
        label="title"
        openDirection="bottom"
        placeholder="Ketik untuk mulai pencarian surat (min. 3 karakter)"
        selectLabel="Tekan 'enter' untuk memilih"
        selectedLabel="Terpilih"
        track-by="doc_id"
        @search-change="documentFind"
        @select="selectDocument">
        <template slot="option" slot-scope="props">
            <CRow>
                <CCol col="12" class="p-1 m-0"><strong>{{ props.option.title }}</strong> <span v-if="typeProp === ''">[{{ props.option.type }}]</span></CCol>
                <CCol col="12" class="p-1 m-0"><span>{{ props.option.doc_no }}</span></CCol>
            </CRow>
        </template>
        <span slot="noResult">{{ this.searchBox.noResultLabel }}</span>
    </multiselect>
</template>

<script>
export default {
    props: {
        typeProp: {
            type: String,
            default: ''
        },
        favoriteProp: {
            type: Boolean,
            default: null
        },
        archiveStateProp: {
            type: Boolean,
            default: false
        }
        ,
        titleTypeProp: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
			searchBox: {
                loading: false,
                options: [],
                noResult: false,
                noResultLabel: 'Menunggu memasukkan kata kunci pencarian...',
            }
        }
    },
    methods: {
        // APIs
		documentFind (query) {
			if (query.length < 3) {
				this.searchBox.options = []
				this.searchBox.noResultLabel = 'Menunggu memasukkan kata kunci pencarian...'
				this.searchBox.noResult = true
				return false
			}

            var filter = {}

            this.searchBox.loading = true
            if (this.titleTypeProp == true) {
                filter = {
                    type: this.typeProp,
                    title: query
                }
            } else {
                filter = {
                    type: this.typeProp,
                    search: query
                }
            }

            if (this.favoriteProp)
                filter.isFavorite = this.favoriteProp
            if (this.archiveStateProp)
                filter.isDone = true
            
            if(filter.type == 'Masuk' || filter.type == 'Keluar' || filter.type == 'Nota Dinas')
                filter.isDone = false
                
			return new Promise((resolve, reject) => {
				this.$store.dispatch({
					type: 'document/getDocument',
					pagination: {
						limit: 100,
						offset: 0
					},
					filter: filter
				}).then(res => {
					if (!res.result || res.result.length == 0) {
						this.searchBox.options = []
						this.searchBox.noResultLabel = 'Surat tidak ditemukan. Mohon untuk memasukkan kata kunci pencarian lain'
						this.searchBox.noResult = true
					} else {
						this.searchBox.noResult = false
						this.searchBox.options = res.result
					}
				}).catch(err => {
					this.searchBox.options = []
					this.searchBox.noResultLabel = 'Surat tidak ditemukan. Mohon untuk memasukkan kata kunci pencarian lain'
					this.searchBox.noResult = true
				}).finally(() => {
					this.searchBox.loading = false
				})
			})
        },
        selectDocument (selectedDoc) {
            let docId = selectedDoc.doc_id
            let routePath = (selectedDoc.type == 'Keluar') ? '/document_out/detail/'+ docId : '/document_in/detail/'+ docId
			this.$router.push(routePath)
        }
    }
}
</script>