<template>
    <div class="flex-grow-0 p-0 m-0">
        <CButton @click="popupModal.isShow = true" color="danger" size="sm">
            <CIcon name="cil-plus"/> Tambah Baru
        </CButton>
        
        <CModal :show.sync="popupModal.isShow" class="modal-create-doc">
            <template #header>
                <h5 class="modal-title">Susun Surat Baru</h5>
                <CButtonClose @click="popupModal.isShow = false" class="text-gray"/>
            </template>
            <div class="p-1">
                <CRow>
                    <CCol col="6">
                        <CCard class="clickable p-0" @click="showIncomingDocumentModal">
                            <CCardBody class="content-center">
                                <font-awesome-icon icon="inbox" class="text-info" size="2x"/>
                            </CCardBody>
                            <CCardFooter class="content-center">
                                <b>Surat Masuk</b>
                            </CCardFooter>
                        </CCard>
                    </CCol>
                    <CCol col="6">
                        <CCard class="clickable p-0" @click="showPopupOutGoingModal">
                            <CCardBody class="content-center">
                                <font-awesome-icon icon="paper-plane" class="text-warning" size="2x"/>
                            </CCardBody>
                            <CCardFooter class="content-center">
                                <b>Surat Keluar</b>
                            </CCardFooter>
                        </CCard>
                    </CCol>
                </CRow>
            </div>
            <template #footer>
                <div></div>
            </template>
        </CModal>

        <CModal :show.sync="popupOutGoingModal.isShow" class="modal-create-doc">
            <template #header>
                <h5 class="modal-title">Susun Surat Baru</h5>
                <CButtonClose @click="popupOutGoingModal.isShow = false" class="text-gray"/>
            </template>
            <div class="p-1">
                <CRow>
                    <CCol col="6">
                        <CCard class="clickable p-0" @click="showOutgoingDocumentReadyToSendModal">
                            <CCardBody class="content-center">
                                <font-awesome-icon icon="print" class="text-warning" size="2x"/>
                            </CCardBody>
                            <CCardFooter class="content-center">
                                <b>Memindai Surat Keluar</b>
                            </CCardFooter>
                        </CCard>
                    </CCol>
                    <CCol col="6">
                        <CCard class="clickable p-0" @click="showOutgoingDocumentModal">
                            <CCardBody class="content-center">
                                <font-awesome-icon icon="pen" class="text-warning" size="2x"/>
                            </CCardBody>
                            <CCardFooter class="content-center">
                                <b>Membuat Surat Keluar</b>
                            </CCardFooter>
                        </CCard>
                    </CCol>
                </CRow>
            </div>
            <template #footer>
                <div></div>
            </template>
        </CModal>
    </div>
</template>

<script>
export default {
    data () {
        return {
            popupModal: {
                isShow: false
            },
            popupOutGoingModal: {
                isShow: false
            }
        }
    },
    methods: {
        // Navigation & Actions
		showIncomingDocumentModal () {
            this.popupModal.isShow = false
            this.$emit('show-incoming-document-modal')
		},
        showPopupOutGoingModal () {
            this.popupModal.isShow = false
            this.popupOutGoingModal.isShow = true
        },
		showOutgoingDocumentModal () {
            this.popupOutGoingModal.isShow = false
            this.$emit('show-outgoing-document-modal')
		},
        showOutgoingDocumentReadyToSendModal () {
            this.popupOutGoingModal.isShow = false
            this.$emit('show-outgoing-document-ready-to-send-modal')
        }
    }
}
</script>