<template>
    <CModal
        :show.sync="popupModalProp.isShow"
        :closeOnBackdrop="false"
        class="modal-fullscreen">
        <template #header>
            <h5 class="modal-title">{{documentForm.title}}</h5>
            <CButtonClose @click="closePopupModal" class="text-gray"/>
        </template>
        <!-- Body -->
        <v-stepper v-model="e1" editable>
            <!-- Stepper Header -->
            <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" :editable="e1 > 1" step="1">Informasi Umum</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 2" :editable="e1 > 2" step="2">Surat</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 3" step="3">Alur Persetujuan</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="4">Pratinjau</v-stepper-step>
            </v-stepper-header>
            <!-- Stepper Body -->
            <v-stepper-items>
				<v-overlay
					:absolute="true"
					:value="overlayState">
					<v-progress-circular
						indeterminate
						color="red">
					</v-progress-circular>
					{{ overlayText }}
				</v-overlay>
                <!-- General Information -->
                <v-stepper-content step="1">
                    <div class="mb-20">
                        <label class="typo__label">Perihal <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
                        <CInput
                            v-model="documentForm.input.title"
                            placeholder="Perihal"
                            :is-valid="documentForm.validate.title"
                            @change="validateDocumentForm('title')"/>
                        <label class="typo__label" v-if="readySendProp == true">Nomor Surat</label>
                        <CInput
                            v-if="readySendProp == true"
                            v-model="documentForm.input.docNumber"
                            placeholder="Nomor Surat"
                            :is-valid="documentForm.validate.title"
                            @change="validateDocumentForm('title')"/>
                        <!-- <label class="typo__label" v-if="readySendProp == true">Tanggal Publikasi Surat </label>
                        <date-picker
                            v-if="readySendProp == true"
                            v-model="documentForm.input.publishDate"
                            placeholder="Pilih Tanggal Publikasi Surat"
                            class="w-100"
                            valueType="format"
                            :editable="false"
                       ></date-picker> -->
                        <CInput
                            v-model="documentForm.input.docFileId"
                            type="hidden"/>
                        <div class="mb-20">
                            <label class="typo__label">Kategori <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
                            <multiselect
								v-model="documentForm.input.categorySelected"
								:allow-empty="false"
								:close-on-select="true"
								:maxHeight="190"
								:multiple="false"
								:options="categoryOptionsProp"
								deselectLabel="Tekan 'enter' untuk menghapus"
								label="label"
								openDirection="bottom"
								placeholder="Pilih Kategori Surat"
								selectLabel="Tekan 'enter' untuk memilih"
								selectedLabel="Terpilih"
								track-by="id"
								@input="selectCategory">
								<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
							</multiselect>
                        </div> 
                        <label class="typo__label mb-0">Sifat Surat <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
                        <CInputRadioGroup
                            v-model="documentForm.input.trait"
                            class="col-sm-9"
                            :options="documentForm.traitOptions"
                            :inline="true"
                            checked="Biasa"
                            @update:checked="validateDocumentForm('trait', $event)" />
                        <!-- Document Date -->
                        <label class="typo__label">Tanggal <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
                        <CInput
                            v-model="documentForm.input.createdAt"
                            type="date"
                        />
                        <!-- Attachment Source -->
                        <div class="mb-20">
                            <label class="typo__label">Sumber Surat <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
                            <multiselect
                                v-model="attachmentForm.input.source"
                                :allow-empty="false"
                                :close-on-select="true"
                                :multiple="false"
                                :options="attachmentForm.docSourceOptions"
								deselectLabel="Tekan 'enter' untuk menghapus"
								openDirection="top"
                                placeholder="Pilih Sumber Surat"
								selectLabel="Tekan 'enter' untuk memilih"
								selectedLabel="Terpilih"
                                @input="selectSource">
                            </multiselect>
                        </div>
                        <!-- Attachment Source -->
                        <div class="mb-20" v-if="sourceIsTemplate">
                            <label class="typo__label">Pilih Template Surat <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
                            <multiselect
                                v-model="attachmentForm.input.selectedTemplate"
                                :allow-empty="false"
                                :close-on-select="true"
                                :multiple="false"
                                :options="templateNameList"
								deselectLabel="Tekan 'enter' untuk menghapus"
								openDirection="top"
                                placeholder="Pilih Template Surat"
								selectLabel="Tekan 'enter' untuk memilih"
								selectedLabel="Terpilih"
                                @input="pickTemplate">
                            </multiselect>
                        </div>
                        <label class="">Informasi Tambahan</label>
                        <div>
                            <label class="">Ringkasan Surat</label>
                            <CTextarea
                                v-model="documentForm.input.description"
                                placeholder="Tuliskan ringkasan surat"/>
                        </div>
                        <div class="mb-20">
							<label class="typo__label">Takah</label>
							<multiselect
								v-model="documentForm.input.takah"
								:allow-empty="true"
								:close-on-select="true"
								:maxHeight="300"
								:multiple="false"
								:options="documentForm.takahOptions"
								deselectLabel="Tekan 'enter' untuk menghapus"
								label="label"
								openDirection="top"
								placeholder="Pilih takah"
								selectLabel="Tekan 'enter' untuk memilih"
								selectedLabel="Terpilih"
								track-by="id">
							</multiselect>
						</div>
                    </div>
                </v-stepper-content>
                <!-- Attachment -->
                <v-stepper-content step="2">
                    <div style="min-height:60vh">
                        <!-- Upload File -->
                        <div v-if="attachmentForm.input.source == 'Unggah Surat'" class="mb-20">
                            <div class="mb-20">
                                <div role="group" class="form-group">
                                    <label class="">Pilih Surat</label>
                                    <CInputFile
                                        ref="file"
                                        accept=".doc,.docx,.pdf"
                                        :placeholder="attachmentForm.placeholder"
                                        custom
                                        @change="validateAttachmentForm"/>
                                    <CInput
                                        v-model="attachmentForm.input.filename"
                                        type="hidden" />
                                    <!-- <small class="d-inline-block form-text text-danger">(*) Berkas di bawah 5MB dan/atau di bawah 20 halaman</small> -->
                                </div>
                            </div>
                        </div>
                        <!-- Scan Document -->
                        <div v-else-if="attachmentForm.input.source == 'Pindai Surat'">
							<!-- <iframe src="http://localhost:3000/scan.html" id="scanner-iframe" frameborder="0" scrolling="no" width="100%" height="600px"></iframe> -->
                            <!-- <iframe :src="baseURL + '/scan.html'" id="scanner-iframe" frameborder="0" scrolling="no" width="100%" height="600px"></iframe> -->
                            <Twain
                                v-if="twainShow"
								v-bind:pdf-data="pdfData"
								v-bind:container-id="`dwtControlContainerOutgoing`"
							/>
                        </div>
                        <!-- Choose Template -->
                        <div v-else class="p-0 m-0">
                            <CRow>
                                <CCol col="8">
                                    
                                    <CCard class="p-9">
                                        <document-preview v-bind:doc-file-id-prop="template_docFileId" />
                                    </CCard>
                                </CCol>
                                <CCol col="4">
                                    <CTextarea
                                        v-for="(item, index) in attachmentForm.input.dynamicField"
                                        :key="index"
                                        v-model="item.model"
                                        :label="item.label.replace('_', ' ')"
                                        :placeholder="item.placeholder.replace('_', ' ')"
                                        :disabled="templateLoader"
                                        @input="changeData(item)"
                                        rows="3" />
                                        
                                    <CButton 
                                        color="success"
                                        @click="generateDataFromTemplate()"
                                        >
                                        <font-awesome-icon v-if="templateLoader" icon="spinner" spin/> Generate Data
                                    </CButton>
                                </CCol>
                            </CRow>
                        </div>
                    </div>
                </v-stepper-content>
                <!-- Approval Workflow -->
                <v-stepper-content step="3">
                    <div class="mb-20" style="min-height:60vh">
                        <div class="mb-20" v-if="readySendProp == false" validateWorkflowForm>
                            <label class="typo__label">Gunakan Konfigurasi? <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
                            <multiselect
                                v-model="documentForm.input.selectedWorkflowOption"
                                :allow-empty="false"
                                :close-on-select="true"
                                :multiple="false"
                                :options="workflowForm.isWorkflowOptions"
								deselectLabel="Tekan 'enter' untuk menghapus"
								label="label"
                                placeholder="Gunakan Konfigurasi?"
								selectLabel="Tekan 'enter' untuk memilih"
								selectedLabel="Terpilih"
								track-by="value"
                                @input="selectWorkflowOption">
                            </multiselect>
                        </div>
                        <div v-if="documentForm.input.isWorkflow == 'yes'" class="mb-20">
                            <label class="typo__label">Alur Konfigurasi <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
                            <multiselect
                                v-model="documentForm.input.selectedWorkflow"
                                :allow-empty="false"
                                :close-on-select="true"
                                :maxHeight="200"
                                :multiple="false"
                                :options="workflowForm.workflowOptions"
                                deselectLabel="Tekan 'enter' untuk menghapus"
                                label="workflow_config"
								openDirection="bottom"
                                placeholder="Pilih Konfigurasi Alur Persetujuan"
								selectLabel="Tekan 'enter' untuk memilih"
								selectedLabel="Terpilih"
                                track-by="workflow_id"
                                @input="selectWorkflow">
                            </multiselect>
                        </div>
                        <div v-else class="mb-20" >
                            <label class="typo__label">PIC Persetujuan  <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
                            <multiselect
                                v-if="readySendProp == false"
                                v-model="documentForm.input.selectedApproval"
                                :allow-empty="false"
                                :close-on-select="true"
                                :maxHeight="200"
                                :multiple="false"
                                :options="documentForm.receiverOptions"
								deselectLabel="Tekan 'enter' untuk menghapus"
								label="label"
								openDirection="bottom"
                                placeholder="Pilih Pengguna sebagai PIC Persetujuan Surat"
								selectLabel="Tekan 'enter' untuk memilih"
								selectedLabel="Terpilih"
								track-by="id"
                                @input="selectApprover">
                            </multiselect>

                            <!-- Multiply approval -->
                            <multiselect
                                v-if="readySendProp == true"
                                v-model="documentForm.input.selectedMultiplyApproval"
                                :allow-empty="true"
                                :close-on-select="false"
                                :maxHeight="200"
                                :multiple="true"
                                :options="documentForm.receiverOptions"
								deselectLabel="Tekan 'enter' untuk menghapus"
								label="label"
								openDirection="bottom"
                                placeholder="Pilih Pengguna sebagai PIC Persetujuan Surat"
								selectLabel="Tekan 'enter' untuk memilih"
								selectedLabel="Terpilih"
								track-by="id"
                                @input="selectApprover">
                            </multiselect>
                        </div>
                        <!-- Sender Document -->
                        <div class="mb-20">
                        <label class="typo__label">Dari</label>
                        <CInput
                            v-model="documentForm.input.sender"
                            placeholder="Dari"
                            @change="validateDocumentForm('sender')"/>
                        </div>
                        <!-- Receiver Document -->
                        <div class="mb-20">
                            <div class="mb-20">
                                <label class="typo__label">Ditujukan Kepada</label>
                                <multiselect
                                    v-model="documentForm.input.userTarget"
                                    :allow-empty="true"
                                    :close-on-select="false"
                                    :maxHeight="200"
                                    :multiple="true"
                                    :options="documentForm.receiverAllOptions"
                                    deselectLabel="Tekan 'enter' untuk menghapus"
                                    label="label"
                                    openDirection="top"
                                    placeholder="Pilih beberapa pengguna untuk menandai"
                                    selectLabel="Tekan 'enter' untuk memilih"
                                    selectedLabel="Terpilih"
                                    track-by="id"
                                    @input="validateDocumentForm('userTarget')">
                                </multiselect>
                            </div>
                        </div>
                        <!-- Tag -->
                        <div class="mb-20">
                            <div class="mb-20">
                                <label class="typo__label">Tembusan</label>
                                <multiselect
                                    v-model="documentForm.input.userTagged"
                                    :allow-empty="true"
                                    :close-on-select="false"
                                    :maxHeight="200"
                                    :multiple="true"
                                    :options="documentForm.receiverAllOptions"
                                    deselectLabel="Tekan 'enter' untuk menghapus"
                                    label="label"
                                    openDirection="top"
                                    placeholder="Pilih beberapa pengguna untuk menandai"
                                    selectLabel="Tekan 'enter' untuk memilih"
                                    selectedLabel="Terpilih"
                                    track-by="id"
                                    @input="validateDocumentForm('userTagged')">
                                </multiselect>
                            </div>
                        </div>
                        <!-- Additional Attachment -->
                        <label class="">Lampiran Tambahan</label>
                        <div class="mb-20">
                            <label class="">Pilih Dari Arsip Surat</label>
                            <attachment-document
                                v-model="documentForm.input.archiveDocAttachments"
                            />
                        </div>
                        <div role="group" class="form-group">
                            <label class="">Unggah Dari Komputer</label>
                            <CInputFile
                                ref="additional_attachment"
                                accept=".doc,.docx,.pdf"
                                :placeholder="documentForm.placeholder"
                                :multiple="true"
                                custom
                                @change="additionalAttachmentChange" />
                        </div>
                    </div>
                </v-stepper-content>
                <!-- Preview -->
                <v-stepper-content step="4">
					<CRow>
						<CCol col="8">
							<document-preview v-bind:doc-file-id-prop="docPreviewFileId" />
						</CCol>
						<CCol col="4">
							<CCard class="p-0">
								<CCardBody>
									<table id="detail-info-table" class="table table-borderless">
										<tbody>
                                            <!-- Title -->
											<tr>
												<td width="90px">Perihal</td>
												<td width="2px">:</td>
												<td>{{ documentForm.input.title }}</td>
											</tr>
                                            <!-- Penomoran -->
											<tr v-if="readySendProp == true">
												<td width="90px">Nomor Surat</td>
												<td width="2px">:</td>
												<td>{{ documentForm.input.docNumber }}</td>
											</tr>
                                            
                                            <!-- Penomoran -->
											<!-- <tr v-if="readySendProp == true">
												<td width="90px">Tanggal Publikasi Surat</td>
												<td width="2px">:</td>
												<td>{{ this.$moment(documentForm.input.publishDate).format("Y-MM-DD") }}</td>
											</tr> -->
                                            <!-- Type -->
											<tr>
												<td width="90px">Tipe Surat</td>
												<td width="2px">:</td>
												<td>{{ documentForm.input.type }}</td>
											</tr>
                                            <!-- Trait -->
											<tr>
												<td width="90px">Sifat Surat</td>
												<td width="2px">:</td>
												<td>{{ documentForm.input.trait }}</td>
											</tr>
                                            <!-- Workflow Config -->
                                            <tr v-if="documentForm.input.isWorkflow == 'yes'">
												<td width="90px">Konfigurasi Alur</td>
												<td width="2px">:</td>
												<td>{{ (documentForm.input.selectedWorkflow != null) ? documentForm.input.selectedWorkflow.workflow_config : '' }}</td>
											</tr>
                                            <!-- Workflow Config -->
                                            <tr v-else>
												<td width="90px">PIC Persetujuan</td>
												<td width="2px">:</td>
												<td>{{ (documentForm.input.selectedApproval != null) ? documentForm.input.selectedApproval.label : '' }} {{ (documentForm.input.selectedMultiplyApproval.length > 0) ?  listMultiplyApproval(): '' }}</td>
                                            </tr>
                                            <!-- Created At -->
											<tr>
												<td width="90px">Dibuat Pada</td>
												<td width="2px">:</td>
												<td>{{ this.$moment(documentForm.input.createdAt).format("Y-MM-DD, HH:mm") }}</td>
											</tr>
											<!-- Receiver Document -->
											<tr>
												<td width="90px">Ditujukan Kepada</td>
												<td width="2px">:</td>
												<td>{{ documentForm.input.userTargetPos}}</td>
											</tr>
											<!-- Tag -->
											<tr>
												<td width="90px">Tembusan</td>
												<td width="2px">:</td>
												<td>{{ documentForm.input.userTaggedPos.toString() }}</td>
											</tr>
                                            <tr>
												<td colspan="3">
													<CButton 
														size="sm"
														color="secondary"
														@click="collapse.show = !collapse.show">{{ collapse.text }}
													</CButton>
												</td>
											</tr>
											<tr>
												<td colspan="3">
													<CCollapse :show="collapse.show">
														{{ documentForm.input.description }}
													</CCollapse>
												</td>
											</tr>
										</tbody>
									</table>
								</CCardBody>
							</CCard>
						</CCol>
					</CRow>
				</v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <template #footer>
            <CButton v-if="e1 == 1" @click="closePopupModal" class="btn-md">Batal</CButton>
			<CButton v-else class="btn-md" @click="goBack">Sebelumnya</CButton>

            <CButton v-if="e1 == 1" color="success" class="btn-md" @click="e1 += 1, attachmentForm.uploadBtn.disabled = true, twainShow = true" :disabled="documentForm.submitBtn.disabled">
                Lanjut
            </CButton>
            <CButton v-if="e1 == 2" color="success" class="btn-md" @click="uploadFile()" :disabled="attachmentForm.uploadBtn.disabled">
                <font-awesome-icon v-if="attachmentForm.uploadBtn.loader" icon="spinner" spin/> {{ attachmentForm.uploadBtn.text }}
            </CButton>
            <CButton v-if="e1 == 3" color="success" class="btn-md" @click="loadPreview()" :disabled="documentForm.submitBtn.disabled">
                Lanjut
            </CButton>
            <CButton v-if="e1 == 4" color="success" class="btn-md" @click="saveItem()" :disabled="documentForm.submitBtn.disabled">
                <font-awesome-icon v-if="documentForm.submitBtn.loader" icon="spinner" spin/> {{ documentForm.submitBtn.text }}
            </CButton>
        </template>
    </CModal>
</template>

<script>
import {
    mapState
} from 'vuex'

import AttachmentDocument from '@/components/document/AttachmentDocument.vue'
import DocumentPreview from '@/components/document/DocumentPreview.vue'
import Twain from '@/components/document/Twain.vue'
export default {
    props: {
		popupModalProp: {
			type: Object,
			required: true
		},
        categoryOptionsProp: {
			type: Array,
			default: function() {
				return null
			}
		},
		tagOptionsProp: {
			type: Array,
			default: function() {
				return null
			}
        },
        notaDinasProp: {
			type: Boolean,
			required: false
		},
        readySendProp: {
            type: Boolean,
            required: false,
        }
    },
    components: {
        'attachment-document': AttachmentDocument,
		'document-preview': DocumentPreview,
        Twain,
	},
    data () {
        return {
			e1: 1,
            baseURL: process.env.VUE_APP_SERVER,
			saveState: {
                attachment: false,
				document: false
            },
            documentForm: {
                title: this.setTitleForm(),
                categoryOptions: this.categoryOptionsProp,
                receiverOptions: [],
                tagOptions: [],
                receiverAllOptions: [],
                takahOptions: [],
                traitOptions: ['Biasa', 'Rahasia', 'Sangat Rahasia'],
                fromOptions: ['Internal', 'Eksternal'],
                input: {
					title: null,
					docNumber: null,
					categoryId: null,
					categorySelected: null,
                    docFileId: null,
                    // Workflow
                    isWorkflow: 'yes',
                    selectedWorkflowOption: { label: 'Ya', value: 'yes' },
                    workflow: null,
                    selectedWorkflow: null,
                    approval: null,
                    selectedApproval: null,
                    selectedMultiplyApproval: [],
                    // Tags
					tags: [],
                    userTagged: [],
                    userTaggedPos: [],
					// Additional Attachment
                    archiveDocAttachments: [],
                    attachmentsEDoc: [],
					additionalAttachments: [],
                    attachments: [],
                    // Marks
					type: (this.notaDinasProp)?'Nota Dinas':'Keluar',
					from: 'Internal',
                    trait: 'Biasa',
                    dari: '',
                    createdAt: null,
                    takah: null,
                    description: null,
                    selectedTemplate: null,
                    readySend: null,
                    userTarget: [],
                    userTargetPos: '',
                    userReceivers: [],
                    publishDate: null
				},
				defaultValue: {
					title: null,
					docNumber: null,
					categoryId: null,
					categorySelected: null,
                    docFileId: null,
                    // Workflow
                    isWorkflow: 'yes',
                    selectedWorkflowOption: { label: 'Ya', value: 'yes' },
                    workflow: null,
                    selectedWorkflow: null,
                    approval: null,
                    selectedApproval: null,
                    selectedMultiplyApproval: [],
                    // Tags
					tags: [],
                    userTagged: [],
                    userTaggedPos: [],
					// Additional Attachment
					archiveDocAttachments: [],
                    attachmentsEDoc: [],
					additionalAttachments: [],
                    attachments: [],
                    // Marks
					type: (this.notaDinasProp)?'Nota Dinas':'Keluar',
					from: 'Internal',
                    trait: 'Biasa',
                    dari: '',
                    createdAt: null,
                    takah: null,
                    description: null,
                    // receiver
                    userTarget: [],
                    userTargetPos: '',
                    userReceivers: [],
                    publishDate: null
				},
				validate: {
					title: null,
                    docFileId: null,
					categorySelected: null,
                    userTagged: null,
                    approval: null,
                    selectedTemplate: null,
                    userTarget: null,
                },
                submitBtn: {
                    loader: false,
                    text: 'Lanjut',
                    disabled: true
                },
                placeholder: 'Pilih lampiran tambahan',
            },
            attachmentForm: {
                docSourceOptions: ['Unggah Surat', 'Pindai Surat'],
                templateOptions: [],
                input: {
                    source: 'Unggah Surat',
                    file: null,
                    filename: null,
                    fileBase64: [],
					scannedFileCount: 0,
                    templateId: null,
                    selectedTemplate: null,
                    dynamicField: [],
                    selectedEmailDoc: null
                },
                defaultValue: {
                    source: 'Unggah Surat',
                    file: null,
                    filename: null,
                    fileBase64: [],
					scannedFileCount: 0,
                    templateId: null,
                    selectedTemplate: null,
                    dynamicField: [],
                    selectedEmailDoc: null
                },
                validate: {
                    file: null,
                    filename: null
                },
                placeholder: 'Pilih Surat (DOC,DOCX,PDF)',
                uploadBtn: {
                    loader: false,
                    text: 'Lanjut',
                    disabled: true
                }
            },
            workflowForm: {
                isWorkflowOptions: [{label: 'Ya', value: 'yes'}, {label: 'Tidak', value: 'no'}],
                workflowOptions: [],
                validate: {
                    approval: null
                },
                submitBtn: {
                    loader: false,
                    text: 'Simpan',
                    disabled: true
                }
            },
            docPreviewFileId: null,
            collapse: {
                show: false,
                text: "Tampilkan deskripsi"
            },
            templateList: [],
            templateNameList: [],
            sourceIsTemplate: false,
            templateIdFromTemplateSource: null,
            template_docFileId: null,
            templateLoader: false,
            
			pdfData: {
				base64Data: null,
				fileName: null,
			},

            twainShow: false
        }
    },
    computed: {
        currentDate() {
			let date_ob = new Date()
			// adjust 0 before single digit date
			let date = ("0" + date_ob.getDate()).slice(-2)
			// current month
			let month = ("0" + (date_ob.getMonth() + 1)).slice(-2)
			// current year
			let year = date_ob.getFullYear()
			// prints date in YYYY-MM-DD format
			let currentDate = year + "-" + month + "-" + date
			return currentDate
		},
		overlayState() {
			return (this.attachmentForm.uploadBtn.loader === true 
				|| this.documentForm.submitBtn.loader === true) ? true : false
		},
		overlayText() {
			return (this.attachmentForm.uploadBtn.loader === true)
				? this.attachmentForm.uploadBtn.text
				: (this.documentForm.submitBtn.loader === true)
					? this.documentForm.submitBtn.text
					: ''
		},
		...mapState('document', {
			uploadProgressBtn: 'uploadProgressBtn'
		})
    },
    watch: {
        tagOptionsProp: {
            handler: function (newValue, oldValue) {
				this.documentForm.receiverOptions = newValue
				this.documentForm.tagOptions = newValue
            },
            deep: true,
            imediate: true
		},
		uploadProgressBtn: {
            handler: function (newValue, oldValue) {
				if (newValue) {
					if (newValue >= 0 && newValue <= 100) this.attachmentForm.uploadBtn.text = 'Memproses... ('+newValue+'%)'
					if (newValue == 100) {
						setTimeout( e => { 
							this.attachmentForm.uploadBtn.text = 'Unggah selesai, mohon menunggu!'
						}, 1000)
					}
				}
            },
            deep: true,
            imediate: true
		},
        popupModalProp: {
            handler: function (newValue, oldValue) {
                if(newValue.isShow) {
                    
                    this.attachmentForm.uploadBtn.text = 'Lanjut'
                    if(this.readySendProp) {
                        this.documentForm.input.selectedWorkflowOption = {label: 'Tidak', value: 'no'}
                        this.documentForm.input.isWorkflow = 'no'
                    }
                }
            },
            deep: true
        },
        
        pdfData: {
            handler: function(newData, oldData) {
				if(newData.base64Data != null) {
					this.attachmentForm.uploadBtn.disabled = false
				}
            },
            deep: true,
            imediate: true
        }
    },
    
    mounted () {
        // this.getTemplateList()
            var fullUrl = window.location.origin + this.$route.path
			if(fullUrl.includes("pkso.teknusa")) {
				this.baseURL = process.env.VUE_APP_SERVER
			} else {
				this.baseURL = process.env.VUE_APP_SERVER_PROD
			}
            window.document.addEventListener('validateScanInput', (event) => {
			console.log('event validatescaninput is called')
			console.log(event)

			const scannedFileCount = event.detail.bufferCount
			const filename = event.detail.filename
			this.attachmentForm.input.scannedFileCount = scannedFileCount
			this.attachmentForm.input.filename = filename
            this.validateAttachmentForm()
            event.stopPropagation()
		})
		window.document.addEventListener('injectScannedFiles', (event) => {
			console.log('event injectScannedFiles is called')
			console.log(event.detail)
			this.injectScannedFiles(event.detail)
            event.stopPropagation()
		})
        this.getWorkflowOptions()
        this.getTakahOptions()
        this.getReceiverOptions()
        this.documentForm.input.createdAt = this.currentDate
        this.documentForm.defaultValue.createdAt = this.currentDate
    },
    methods: {
        setTitleForm(){
            if(this.notaDinasProp) {
                return "Susun Nota Dinas"
            }
            if(this.readySendProp) {
                return "Memindai Surat Keluar"
            }
            return "Membuat Surat Keluar"
        },
        // Back Step
		goBack () {
			this.e1-=1
            this.documentForm.submitBtn.disabled = false
            if(this.e1 == 2) {
                this.attachmentForm.uploadBtn.disabled = false
            }
		},
        // APIs
        // Attachment
		selectSource (selectedSource) {
            if(selectedSource == 'Template') {
                this.sourceIsTemplate = true
                this.documentForm.validate.selectedTemplate = false 
            } else {
                this.sourceIsTemplate = false
                this.documentForm.validate.selectedTemplate = false 
            }
            console.log('selet document source')
            this.attachmentForm.input.source = selectedSource
            // Reset upload document
            this.attachmentForm.input.file = null
            this.attachmentForm.input.filename = null
			this.attachmentForm.input.fileBase64 = []
            this.attachmentForm.validate = {
                file: null,
                filename: null
            }
            this.attachmentForm.placeholder = 'Pilih Surat (DOC,DOCX,PDF)'
            this.attachmentForm.uploadBtn = {
                loader: false,
                text: 'Lanjut',
                disabled: true
            }
            // Reset template
            this.attachmentForm.input.templateId = null
            this.attachmentForm.input.selectedTemplate = null
            this.attachmentForm.input.dynamicField = null
            
            this.validateDocumentForm('')
        },
        pickTemplate() {
            var selectedTemplateData = this.templateList.filter(data=> data.template_name == this.attachmentForm.input.selectedTemplate)[0]
            this.documentForm.input.selectedTemplate = selectedTemplateData
            this.selectTemplate(selectedTemplateData)
            this.templateIdFromTemplateSource = selectedTemplateData.template_id
        },
        getTemplateOptions (selectedCategoryId) {
            
            this.$store.dispatch({
				type: 'document/getTemplate',
				filter: {
                    categoryId: selectedCategoryId
                }
			}).then( res => {
                if(res!=null) {
                    if(res.count > 0) {
                        this.templateList = res.result

                        this.templateNameList = []
                        res.result.forEach(data=> {
                            this.templateNameList.push(data.template_name)
                        })

                        this.attachmentForm.docSourceOptions = ['Unggah Surat', 'Pindai Surat', 'Template']
                    
                        this.$toasted.success('Template Surat Tersedia').goAway(3000)
                    } else {
                        this.$toasted.error('Template Surat Tidak Tersedia').goAway(3000)
                    }
                } else {
                    this.$toasted.error('Template Surat Tidak Tersedia').goAway(3000)
                }
			}).catch( err => {
                this.$toasted.error('Template Surat Tidak Tersedia').goAway(3000)
                this.attachmentForm.docSourceOptions = ['Unggah Surat', 'Pindai Surat']
            })
        },
        selectCategory (selectedCategory) {
            this.documentForm.input.categoryId = selectedCategory.id
            this.getTemplateOptions(selectedCategory.id)
            this.validateDocumentForm('categorySelected')
        },
        uploadFile () {
            if (this.attachmentForm.input.source == 'Pindai Surat') {
				// Call out iframe function getBase64ScannedFiles
				this.proccessTwainScannedFile()
            } else if (this.attachmentForm.input.source == 'Template') {
                this.uploadTemplate()
            } else {
                this.uploadDocument()
            }
        },

		proccessTwainScannedFile() {
			this.attachmentForm.input.fileBase64.push(this.pdfData.base64Data)
			this.attachmentForm.input.filename = this.pdfData.fileName
			this.uploadDocumentBase64()
		},

        // Upload Document
        uploadDocument () {
			this.attachmentForm.uploadBtn = {
                loader: true,
                text: 'Memproses...',
				disabled: true
			}
			this.$store.dispatch({
				type: 'document/uploadDocument',
				formInput: {
					file: this.attachmentForm.input.file,
					filename: this.attachmentForm.input.filename
				}
			}).then( res => {
                // Inject documentForm docFileId with new created docFile
                const docFileId = res.result.doc_file_id
                if (docFileId != null && docFileId != undefined) {
                    this.documentForm.input.docFileId = docFileId
                    this.validateWorkflowForm("docFileId")
                    this.attachmentForm.placeholder = this.attachmentForm.input.filename
                    this.e1 += 1
                    this.documentForm.submitBtn.disabled = true
                } else {
                    console.log('doc_file_id is undefined or null')
                }
			}).catch( err => {
				this.$toasted.error('Gagal mengunggah surat, silahkan coba kembali').goAway(3000)
            }).finally( () => {
                this.attachmentForm.uploadBtn = {
					loader: false,
					text: 'Lanjut',
					disabled: true
				}
            })
        },
        // Template
        selectTemplate(selectedTemplate) {
            let templateId = selectedTemplate.template_id
            this.$store.dispatch({
				type: 'template/fetch',
				formInput: {
                    templateId: templateId,
                    outputType: 'details'
                }
			}).then( res => {
                let dynamicFields = []
                res.result.template_field.forEach((obj) => {
                    let dynamicInput = {
                        label: obj,
                        placeholder: 'Masukkan '+ obj,
                        model: ''
                    }
                    dynamicFields.push(dynamicInput)
                })
                this.attachmentForm.input.dynamicField = dynamicFields
                this.attachmentForm.uploadBtn.disabled = false
                this.documentForm.validate.selectedTemplate = true
                this.validateDocumentForm('')
                this.generateDataFromTemplate()
			}).catch( err => {
				this.$toasted.error('Gagal memuat data, silahkan coba kembali!').goAway(3000)
			})
        },
        uploadTemplate () {

            this.attachmentForm.uploadBtn = {
                loader: true,
                text: 'Lanjut',
                disabled: true
            }

            let templateId = this.templateIdFromTemplateSource
            let filename = this.documentForm.input.title.trim() +'.docx'
            let dynamicField = this.attachmentForm.input.dynamicField
            let formInput = {
                templateId,
                filename,
                dynamicField,
                isDraft: false
            }

			this.$store.dispatch({
				type:'document/createDocumentByTemplate',
				formInput
			}).then(res => {
                const docFileId = res.result.doc_file_id
                if (docFileId != null && docFileId != undefined) {
                    this.documentForm.input.docFileId = docFileId
                    this.validateWorkflowForm("docFileId")
                    this.e1 += 1
                    this.documentForm.submitBtn.disabled = true
                } else {
                    console.log('doc_file_id is undefined or null')
                }
			}).catch( err => {
				this.$toasted.error('Gagal menambah surat, silahkan coba kembali!').goAway(3000)
			}).finally( () => {
                this.attachmentForm.uploadBtn = {
					loader: false,
					text: 'Lanjut',
					disabled: true
				}
            })
        },
        // Scan
		injectScannedFiles (data) {
			console.log('function injectScannedFiles is called')
			console.log(data)
			const base64data = data.base64data
			const filename = data.filename
			this.attachmentForm.input.fileBase64.push(base64data)
			this.attachmentForm.input.filename = filename
			this.uploadDocumentBase64()
		},
		uploadDocumentBase64 () {
            if (this.attachmentForm.input.fileBase64.length == 0 ||
                this.attachmentForm.uploadBtn.loader === true) {
				console.log('file base64 attachment is empty')
				return
			}

			this.attachmentForm.uploadBtn = {
                loader: true,
                text: 'Lanjut',
                disabled: true
            }
			let filename = this.attachmentForm.input.filename
			filename += (filename.indexOf('.docx') == -1 || filename.indexOf('.pdf') == -1) ? '.pdf' : ''
			this.$store.dispatch({
				type: 'document/uploadDocumentBase64',
				formInput: {
					pdfBase64: this.attachmentForm.input.fileBase64[0],
					filename: filename
				}
			}).then( res => {
				// Inject documentForm docFileId with new created docFile
				const docFileId = res.result.doc_file_id
				this.documentForm.input.docFileId = docFileId
                this.validateWorkflowForm("docFileId")
                // this.$toasted.success('Sukses mengunggah surat').goAway(3000)
				this.attachmentForm.placeholder = this.attachmentForm.input.filename
				this.e1 += 1
			}).catch( err => {
				this.$toasted.error('Gagal mengunggah surat, silahkan coba kembali').goAway(3000)
            }).finally( () => {
				this.attachmentForm.uploadBtn = {
					loader: false,
					text: 'Lanjut',
					disabled: true
				}
			})
		},
        // Workflow
        getWorkflowOptions () {

            if(this.notaDinasProp) {
                this.$store.dispatch({
                    type: 'workflow/get',
                    pagination: {
                        limit: 100,
                        offset: 0
                    }
                }).then( res => {
                    this.workflowForm.workflowOptions = res.result
                }).catch( err => {
                    this.$toasted.error('Gagal mengunggah surat, silahkan coba kembali').goAway(3000)
                })
            } else {
                

            this.$store.dispatch({
				type: 'workflow/get',
				pagination: {
                    limit: 100,
                    offset: 0
                },
                
                filter: {
                        isDisposition: false
                    }
			}).then( res => {
				this.workflowForm.workflowOptions = res.result
			}).catch( err => {
				this.$toasted.error('Gagal mengunggah surat, silahkan coba kembali').goAway(3000)
            })
            }
        },
        getTakahOptions () {
			this.$store.dispatch({
				type: 'takah/loadData',
				filter: { access: "all" },
				pagination: {
					limit: 200,
					offset: 0
				}
			}).then( res => {
				let items = []
                if (res) {
                    res.result.forEach((item) => {
                        let rowData = {
                            id: item.takah_id,
                            name: item.takah_id,
                            label: '',
                        }
						if (item.takah_no) {
							rowData.label = item.takah_no + '-' + item.takah
						} else {
							rowData.label = '[No Number] -' + item.takah
						}
                        items.push(rowData)
                    })
                }
				
				this.documentForm.takahOptions = items
			})
		},
        selectWorkflowOption (selectedOption) {
            this.documentForm.input.isWorkflow = selectedOption.value
            this.documentForm.tagOptions = this.documentForm.receiverOptions
            this.documentForm.input.selectedWorkflow = null
            this.documentForm.input.selectedApproval = null
            this.validateWorkflowForm('approval')
        },
        filterTagOptions (userIds) {
            let selectedTagOptions = this.documentForm.input.userTagged
            let tagOptions = this.documentForm.receiverOptions

            for (let i = 0; i < userIds.length; i++) {
                const userId = userIds[i];
                // Exclude selected tag user if same with selected workflow/direct users
                let filteredSelectedTagOptions = selectedTagOptions.filter(function( obj ) {
                    return obj.id !== userId
                })
                selectedTagOptions = filteredSelectedTagOptions

                // Exclude available tag user if same with selected workflow/direct users
                let filteredTagOptions = tagOptions.filter(function( obj ) {
                    return obj.id !== userId
                })
                tagOptions = filteredTagOptions
            }
            this.documentForm.input.userTagged = selectedTagOptions
            this.documentForm.tagOptions = tagOptions
        },
        selectWorkflow (selectedWorkflow) {
            this.documentForm.input.workflow = selectedWorkflow.workflow_config
            this.validateWorkflowForm('approval')
            let workflowUserIds = []
            for (let i = 0; i < selectedWorkflow.flow.length; i++) {
                const flowProp = selectedWorkflow.flow[i]
                const userId = flowProp.user_id
                workflowUserIds.push(userId)
            }
            this.filterTagOptions(workflowUserIds)
        },
        selectApprover (selectedApprover) {
            this.documentForm.input.approval = selectedApprover.userId
            this.validateWorkflowForm('approval')
            this.filterTagOptions([selectedApprover.userId])
        },
        // Preview
        loadPreview () {
            this.docPreviewFileId = this.documentForm.input.docFileId
            this.e1 += 1
        },
        // Additional Attachment - Archive Doc
		extrachArchiveDocAttachments () {
			let docFileIds = []
			let archiveDocAttachments = this.documentForm.input.archiveDocAttachments
			if (archiveDocAttachments.length > 0) {
				for (let i = 0; i < archiveDocAttachments.length; i++) {
					const archiveDoc = archiveDocAttachments[i];
					docFileIds.push(archiveDoc.doc_file_id)
				}
			}
			return docFileIds
		},
		// Additional Attachment - Upload
        additionalAttachmentChange (event) {
			let fileNumber = event.length
			if (event && fileNumber > 0) {
				let additionalAttachments = []
				for (let i = 0; i < event.length; i++) {
					const fileProp = event[i];
					let data = {
						filename: fileProp.name,
						file: fileProp
					}
					additionalAttachments.push(data)
				}
				this.documentForm.input.additionalAttachments = additionalAttachments
				this.documentForm.placeholder = fileNumber +' surat telah dipilih'
			}
		},
		uploadAdditionalAttachment (selectedIndex) {
			return new Promise( (resolve, reject) => {
				let additionalAttachment = this.documentForm.input.additionalAttachments[selectedIndex]
				let formInput = {
					filename: additionalAttachment.filename,
					file: [additionalAttachment.file],
				}
				this.$store.dispatch({
					type: 'document/uploadDocument',
					formInput: formInput
				}).then( res => {
					resolve(res.result)
				}).catch( err => {
					reject(err)
				})
			})
		},
        // Store Document
		storeDocument () {
			return new Promise( (resolve, reject) => {
                this.documentForm.input.readySend = this.readySendProp

                if(this.readySendProp) {
                    this.documentForm.input.approval = []
                    this.documentForm.input.selectedMultiplyApproval.forEach(data=> {
                        this.documentForm.input.approval.push(data.id)
                    })
                }

				this.$store.dispatch({
					type: 'document/storeDocument',
					formInput: this.documentForm.input
				}).then( res => {
					resolve(res.result)
				}).catch( err => {
					reject(err)
				})
			})
		},
        async saveItem () {
			// Extract values form multiple inputs
            // Approval
            if (this.documentForm.input.isWorkflow == 'yes') {
                this.documentForm.input.selectedApproval = null
                this.documentForm.input.approval = null
            } else {
                this.documentForm.input.selectedWorkflow = null
                this.documentForm.input.workflow = null
            }
			// User Tags
			let tags = []
			let userTagged = this.documentForm.input.userTagged
			for (let i = 0; i < userTagged.length; i++) {
				let userId = userTagged[i].userId
				tags.push(userId)
			}
            this.documentForm.input.tags = tags

            // User Receivers
            let receivers = []
            let userTarget = this.documentForm.input.userTarget
            for (let i = 0; i < userTarget.length; i++) {
				let userId = userTarget[i].userId
				receivers.push(userId)
			}
            this.documentForm.input.userReceivers = receivers

			// Begin the save process
			this.documentForm.submitBtn = {
                loader: true,
                text: 'Memproses...',
                disabled: true
            }
            // Upload additional attachments
			let additionalAttachments = this.documentForm.input.additionalAttachments
            console.log("Attachment:", additionalAttachments)
			if (additionalAttachments.length > 0) {
				if (!this.saveState.attachment) {
					try {
						let docFileIds = []
						for (let i = 0; i < additionalAttachments.length; i++) {
							let docFileProp = await this.uploadAdditionalAttachment(i)
							let docFileId = docFileProp.doc_file_id
							docFileIds.push(docFileId)
						}
						this.saveState.attachment = true
						this.documentForm.input.attachments = docFileIds
					} catch (err) {
						this.saveState.attachment = false
						this.$toasted.error('Proses unggah lampiran tambahan gagal, silahkan hubungi administrator').goAway(3000)
						this.documentForm.submitBtn = {
							loader: false,
							text: 'Simpan',
							disabled: true
						}
						return false
					}
				}
			}
			// Merge additional attachment with selected doc arcive attachment
            let archiveDocAttachments = this.extrachArchiveDocAttachments()
            this.documentForm.input.attachmentsEDoc = archiveDocAttachments
            
			// Store document
			if (!this.saveState.document) {
				try {
					let documentProp = await this.storeDocument()
					this.saveState.document = true
				} catch (err) {
                    console.log('Failed to store document')
                    console.log(err)
					this.saveState.document = false
				}
			}
            // Finishing the successful save process
            if (this.saveState.document) {
                this.$toasted.success('Sukses menambah data').goAway(3000)
                this.closePopupModal(true)
            } else {
                this.$toasted.error('Gagal menambah surat baru, harap menghubungi administrator').goAway(3000)
                this.documentForm.submitBtn = {
					loader: false,
					text: 'Simpan',
					disabled: true
				}
            }
        },
        // Navigation & Actions
		closePopupModal (refreshState = false) {
            // Reset Stepper
            this.e1 = 1
            // Reset Document Form
            this.documentForm.tagOptions = this.documentForm.receiverOptions
            this.documentForm.input = Object.assign({}, this.documentForm.defaultValue)
            this.documentForm.validate = {
				title: null,
                docNumber: null,
                docFileId: null,
                categorySelected: null,
                userTagged: null
            }
            this.documentForm.submitBtn = {
                loader: false,
                text: 'Lanjut',
                disabled: true
            },
            this.documentForm.placeholder = 'Pilih lampiran tambahan'
            // Reset Attachment Form
            this.attachmentForm.templateOptions = []
            this.attachmentForm.input = Object.assign({}, this.attachmentForm.defaultValue)
            this.attachmentForm.validate = {
				file: null,
                filename: null
            }
            this.attachmentForm.placeholder = 'Pilih Surat (DOC,DOCX,PDF)'
            this.attachmentForm.uploadBtn = {
                loader: false,
                text: 'Lanjut',
                disabled: true
            }
            // Reset Workflow
            this.workflowForm.validate.approval = null
            this.workflowForm.submitBtn = {
                loader: false,
                text: 'Simpan',
                disabled: true
            }
			// Reset save states
			this.saveState = {
                attachment: false,
				document: false
			}
            this.documentForm.submitBtn = {
                loader: false,
                text: 'Simpan',
                disabled: true
            }
            const currRouteName = this.$route.name
            refreshState = (currRouteName == 'Surat Keluar' || currRouteName == 'Nota Dinas') ? refreshState : false
            this.$emit('close-popup-modal', refreshState)
		},
        // Validation
		validateDocumentForm (field, event = null) {
			if (field == 'title') {
				if (this.documentForm.input.title != '') {
                    this.documentForm.validate.title = true
				} else {
                    this.documentForm.validate.title = false
				}
			}

			if (field == 'categorySelected') {
				if (this.documentForm.input.categorySelected != '') {
					this.documentForm.validate.categorySelected = true
				} else {
					this.documentForm.validate.categorySelected = false
				}
            }

            if (field == 'userTarget') {
				if (this.documentForm.input.userTarget != []) {
					this.documentForm.validate.userTarget = true
					let userTargetPos = []
					this.documentForm.input.userTarget.forEach((item) => {
						userTargetPos.push(item.position)
					})
					this.documentForm.input.userTargetPos = userTargetPos.toString()
					this.documentForm.input.to = this.documentForm.input.userTargetPos
				} else {
					this.documentForm.validate.userTarget = false
				}
            }

            if (field == 'userTagged') {
				if (this.documentForm.input.userTagged != []) {
					this.documentForm.input.userTaggedPos = []
					this.documentForm.input.userTagged.forEach((item) => {
						this.documentForm.input.userTaggedPos.push(item.position)
					})
				}
            }

            if (field == 'trait') this.documentForm.input.trait = event
            if (field == 'from') this.documentForm.input.from = event

            if(this.sourceIsTemplate) {

                if (
                    this.documentForm.validate.title &&
                    this.documentForm.validate.categorySelected &&
                    this.documentForm.validate.selectedTemplate
                ) {
                    this.documentForm.submitBtn.disabled = false
                } else {
                    this.documentForm.submitBtn.disabled = true
                }
            } else {

                if (
                    this.documentForm.validate.title &&
                    this.documentForm.validate.categorySelected
                ) {
                    this.documentForm.submitBtn.disabled = false
                } else {
                    this.documentForm.submitBtn.disabled = true
                }
            }
        },
        validateAttachmentForm (event = null) {
            if (this.attachmentForm.input.source == 'Pindai Surat') {
				if (this.attachmentForm.input.scannedFileCount > 0) {
					this.attachmentForm.validate.file = true
				} else {
					this.attachmentForm.validate.file = false
				}
			} else {
                if (event.length > 0) {
                    let fileCollection = []
                    event.forEach((item) => {
                        fileCollection.push(item.name)
                    })
                    this.attachmentForm.placeholder = fileCollection.join()
                    this.attachmentForm.input.file = event
                    let filename = fileCollection.join().replace(' ','_')
                    this.attachmentForm.input.filename = filename
                    this.attachmentForm.validate.file = true
                } else {
                    this.attachmentForm.placeholder = 'Belum memilih surat'
                    this.attachmentForm.validate.file = false
                }
            }
			
			if (this.attachmentForm.input.filename != '') {
				this.attachmentForm.validate.filename = true
			} else {
				this.attachmentForm.validate.filename = false
			}
            
            if (
				this.attachmentForm.validate.file &&
				this.attachmentForm.validate.filename
			) {
				this.attachmentForm.uploadBtn.disabled = false
			} else {
				this.attachmentForm.uploadBtn.disabled = true
			}
        },
        validateWorkflowForm (field = 'approval') {
			if (field == 'docFileId') {
				if (this.documentForm.input.docFileId != null && this.documentForm.input.docFileId != '') {
					this.documentForm.validate.docFileId = true
				} else {
					this.documentForm.validate.docFileId = false
				}
            }
            
            if (field == 'approval') {
                
                if(this.readySendProp == true) {
                    if(this.documentForm.input.selectedMultiplyApproval.length > 0) {
                        this.workflowForm.validate.approval = true
                    } else {
                        this.workflowForm.validate.approval = false
                    }
                } else {

                    let selectedOption =
                        (this.documentForm.input.isWorkflow == 'yes') ?
                            this.documentForm.input.workflow :
                                this.documentForm.input.approval

                    if (selectedOption != null) {
                        this.workflowForm.validate.approval = true
                    } else {
                        this.workflowForm.validate.approval = false
                    }
                }
            }
            

            if (
                this.documentForm.validate.docFileId &&
                this.workflowForm.validate.approval
            ) {
                this.documentForm.submitBtn.disabled = false
            } else {
                this.documentForm.submitBtn.disabled = true
            }
        },
        getTemplateList() {
            this.$store.dispatch({
				type: 'template/getAllTemplate',
				filter: {}
			}).then( res => {
                if(res != null) {
                    this.templateList = res.result
                    
                    res.result.forEach(data=> {
                        this.templateNameList.push(data.template_name)
                    })
                }
			}).catch( err => {
				this.$toasted.error('Gagal mengambil data template').goAway(3000)
            })
        },
        generateDataFromTemplate(){
            this.templateLoader = true
            let templateId = this.templateIdFromTemplateSource
            let filename = this.documentForm.input.title.trim() +'.docx'
            let dynamicField = this.attachmentForm.input.dynamicField
            let formInput = {
                templateId,
                filename,
                dynamicField,
                isDraft: true
            }

			this.$store.dispatch({
				type:'document/createDocumentByTemplate',
				formInput
			}).then(res => {
                this.template_docFileId = res.result.doc_file_id
			}).catch( err => {
				var messageErr = err.response.data.error
				this.$toasted.error(messageErr).goAway(3000)
			}).finally( () => {
            this.templateLoader = false
			})
        },
        changeData(item) {
            var isDisable = true

            for(var index = 0; index < this.attachmentForm.input.dynamicField.length; index++) {
                if(this.attachmentForm.input.dynamicField[index].model.length > 0) {
                    isDisable = false
                    break;
                }
            }
            this.attachmentForm.uploadBtn.disabled = isDisable
        },
        listMultiplyApproval() {
            if(this.documentForm.input.selectedMultiplyApproval.length > 0) {
                var listLabel = []
                this.documentForm.input.selectedMultiplyApproval.forEach(data=> {
                    listLabel.push(data.label)
                })
                return listLabel.join(', ')
            }
            return ''
        },

        getReceiverOptions () {
			this.$store.dispatch(
				{
					type: 'user/getUser',
					pagination: {
						limit: 100000,
						offset: 0
					},
					order: {
                        orderBy: 'fullname',
                        orderType: 'asc'
                    },
                    filter: {
                        isActive: 'true',
                        satkerLimit: 'false'
                    }
				}
			).then( res => {
				let items = []
				for (let i = 0; i < res.result.length; i++) {
					const item = res.result[i];
					let position = (item.position != null) ? item.position : ''
					let positionExisted = items.some(el => el.position === position)
					if ((item.position != this.loggedUserPosition) && !positionExisted) {
						let label = position.trim()
						let rowData = {
							label: label,
							id: item.user_id,
							userId: item.user_id,
							fullname: item.fullname,
							email: item.email,
							rank: item.rank,
							position: item.position,
							command: null
						}
						items.push(rowData)
					}
				}

                if (this.notaDinasProp) {
                this.documentForm.receiverAllOptions = this.documentForm.receiverOptions
                } else {
                    this.documentForm.receiverAllOptions = items
                }
			})
        },
    }
}
</script>